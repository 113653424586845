"use client";
import { toast } from "sonner";

type Errors = {
    errors: {
        [key: string]: string;
    };
};

type Error = {
    error: string;
};

type Message = {
    message: string;
};

export type ErrorData = Errors | Error | Message;

type ToastType = "success" | "warning" | "error";

class ToastMessage {
    private static createToast(
        type: ToastType,
        message: string,
        timeDelay: number = 3000
    ) {
        switch (type) {
            case "success":
                toast.success(message, {
                    duration: timeDelay,
                });
                break;
            case "warning":
                toast.warning(message, {
                    duration: timeDelay,
                });
                break;
            case "error":
                toast.error(message, {
                    duration: timeDelay,
                });
                break;
        }
    }

    public static success(message: string) {
        this.createToast("success", message);
    }
    public static warning(message: string) {
        this.createToast("warning", message);
    }
    public static error(data: ErrorData) {
        if ("errors" in data) {
            const errors = data?.errors;
            for (let key in errors) {
                this.createToast("error", errors?.[key]);
            }
        } else if ("message" in data) {
            this.createToast("error", data?.message);
        } else {
            this.createToast("error", data?.error);
        }
    }
}
export default ToastMessage;
