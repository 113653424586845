"use client"

import React, {
    ChangeEvent,
    useState,
    forwardRef,
    useEffect,
    ReactNode,
    useCallback,
} from "react"
import { Typography } from "."
import { CheckBoxBlanked, CheckBoxFilled } from "../icons"
import { Colors } from "@/app/const/colors"

type placementType = "Right" | "Left" | "Top" | "Bottom"
interface Props {
    inputId?: string
    defaultValue?: string
    label?: ReactNode
    checked?: boolean
    disabled?: boolean
    placement?: placementType
    className?: string
    icon?: ReactNode
    activeIcon?: ReactNode
    onSelect?: (val: boolean) => void
}

const Check = forwardRef<HTMLInputElement, Props>(
    (
        {
            inputId,
            defaultValue,
            onSelect,
            checked = false,
            disabled = false,
            label,
            className = "",
            icon = <CheckBoxBlanked size={24} />,
            activeIcon = <CheckBoxFilled color={Colors.primary} size={24} />,
            placement = "Right",
        }: Props,
        ref
    ) => {
        const [selected, setSelected] = useState(checked)
        useEffect(() => {
            setSelected(checked)
        }, [checked])

        const targetId =
            inputId || `rnd-input-${Math.round(Math.random() * 100000)}`

        let rootClss = `cursor-pointer flex items-center flex-row-reverse gap-y-2 gap-x-4 w-full justify-start `
        if (!!label) {
            switch (placement) {
                case "Left":
                    rootClss += " !flex-row "
                    break
                case "Top":
                    rootClss += " !flex-col-reverse "
                    break
                case "Bottom":
                    rootClss += " !flex-col "
                    break
                default:
                    rootClss += " !flex-row-reverse "
                    break
            }
        }
        let checkClss = "w-5 h-5 flex items-center justify-center rounded-md"

        let currLabel = null
        if (!!label)
            currLabel =
                typeof label === "string" ? (
                    <Typography className="label text-base font-normal" variant="span">
                        {label}
                    </Typography>
                ) : (
                    label
                )

        let innerBullet = icon

        if (selected) innerBullet = activeIcon

        const changeCheckHandler = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                let targetVal = e.target.checked
                setSelected(e.target.checked)
                if (onSelect) onSelect(targetVal)
            },
            [onSelect]
        )

        return (
            <label htmlFor={targetId} className={`${rootClss} ${className}`}>
                <div className={checkClss}>
                    <input
                        type="checkbox"
                        checked={selected}
                        disabled={disabled}
                        {...(defaultValue && { defaultValue })}
                        id={targetId}
                        onChange={changeCheckHandler}
                        hidden
                        ref={ref}
                    />
                    <div className="check-circle p-2 rounded-full active:bg-black/10 hover:bg-black/[.05]">
                        {innerBullet}
                    </div>
                </div>
                {currLabel}
            </label>
        )
    }
)

export default Check
