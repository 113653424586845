"use client";

import React, { ChangeEvent, useState, useEffect } from "react";

interface Props {
  onSelect?: (checked: boolean) => void;
  checked?: boolean;
}

const Switch = ({ onSelect, checked }: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked as boolean);
  }, [checked]);

  const changeSwitchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const targetVal = e.target.checked;
    setIsChecked(targetVal);
    if (onSelect) onSelect(targetVal);
  };

  let pathStyle =
    "bg-gray-200 w-[36px] h-[20px] rounded-full transition-all shadow-inner";

  let thumbStyle =
    "toggle-thumb absolute top-0 w-4 h-4 top-1/2 transition-all -translate-y-1/2 left-[2px] bg-white rounded-full shadow-md";

  if (!!isChecked) {
    pathStyle += " !bg-primary";
    thumbStyle += " !left-[calc(100%-18px)] ";
  }

  return (
    <label className="flex items-center cursor-pointer top">
      <div className="relative">
        <input
          type="checkbox"
          checked={isChecked}
          hidden
          onChange={changeSwitchHandler}
        />
        <div className={pathStyle} />
        <div className={thumbStyle} />
      </div>
    </label>
  );
};

export default Switch;
