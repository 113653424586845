"use client";

import React, { ChangeEvent, useEffect, useState } from "react";

type Props = {
  step?: number;
  maxStep?: number;
  defaultValue?: number;
  handleChange?: (val: number) => void;
};

export default function Slider({
  step = 1,
  maxStep = 5,
  defaultValue,
  handleChange,
}: Props) {
  const [value, setValue] = useState(defaultValue || 0);
  useEffect(() => {
    if (defaultValue !== undefined) setValue(defaultValue);
  }, [defaultValue]);

  const valueChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let targetVal = +e.target.value;
    setValue(targetVal);
    if (handleChange) handleChange(targetVal);
  };

  let calcProgress = 100 - Math.floor((value * 100) / maxStep);
  // let runnerStyle = `linear-gradient(to right, rgba(0,0,0,0.12) ${calcProgress}%, #bb202e 0%)`;
  const calculateStepPosition = (currentStep: number) => {
    const stepPercentage = ((currentStep - 1) / (maxStep + 1 - 1)) * 100;
    return stepPercentage;
  };
  return (
    <>
      <div className="relative">
        <input
          type="range"
          id="slider"
          step={step}
          max={maxStep}
          value={value}
          onChange={valueChangeHandler}
          style={{
            background: "transparent",
            position: "relative",
            top: -3,
          }}
        />
        <div className="absolute w-full top-[50%] translate-y-[-50%] flex flex-row gap-x-1">
          {Array.from(Array(maxStep).keys()).map((step) => {
            let clss = `w-full h-[2px] transition-all rounded-xl bg-black/[.1]`;

            const isActive = step < value;

            if (isActive) clss += ` !bg-primary`;

            return <div className={clss}></div>;
          })}
        </div>
      </div>
    </>
  );
}
