import { Price } from "@/app/components/shared";
import { Badge, Typography } from "@/app/components/ui";
import { calculatePercentageOff, getPriceFormat } from "@/app/utils";
import React, { useMemo } from "react";

type Props = {
  price: number;
  salePrice?: number;
  currency?: string;
  isAvailable?: boolean;
};
export default function ProductPrice({
  price,
  salePrice,
  currency,
  isAvailable = true,
}: Props) {
  const determinePercent = useMemo(() => {
    return calculatePercentageOff(price, salePrice);
  }, [price, salePrice]);

  let content = (
    <Price className='[&_.price]:font-bold [&_.price]:text-lg' price={price} />
  );

  if (salePrice && determinePercent > 0) {
    content = (
      <div className='product-price flex flex-col gap-y-2'>
        <div className='prev-price flex flex-row gap-x-2'>
          <Typography className='line-through text-black/40 font-light'>
            {getPriceFormat("" + price)}
          </Typography>
          <div className='flex flex-col items-center justify-center py-1 px-2 bg-primary text-white rounded-lg text-sm'>
            {`% ${determinePercent}`}
          </div>
        </div>

        {salePrice && (
          <Price
            className='[&_.price]:font-bold [&_.price]:text-lg'
            price={salePrice}
            currency={currency}
          />
        )}
      </div>
    );
  }

  if (!isAvailable)
    content = (
      <Typography className='bg-black/60 text-white px-4 rounded-lg'>
        نا‌موجود
      </Typography>
    );

  return <>{content}</>;
}
