import { Button, Paper } from "../ui";
import React, { MouseEvent } from "react";

type Props = {
  title: string;
  description?: string;
  loading?: boolean;
  onSubmit: () => void;
  onClose: () => void;
  closeText?: string;
  submitText?: string;
};

export default function Prompt({
  title,
  description,
  loading = false,
  onSubmit,
  onClose,
  closeText = "انصراف",
  submitText = "حذف",
}: Props) {
  const paperEventStopHandler = (e: MouseEvent<HTMLDivElement>) =>
    e.stopPropagation();

  return (
    <Paper className="w-[480px] max-w-full" onClick={paperEventStopHandler}>
      <div className="flex flex-col gap-y-[24px]" dir="rtl">
        <div className="flex flex-col gap-y-[8px]">
          <strong className="font-semibold text-xl">{title}</strong>
          {!!description && (
            <span className="text-text-grey text-sm">{description}</span>
          )}
        </div>
        <div className="flex flex-row gap-x-4">
          <Button
            loading={loading}
            className="submit-btn w-full"
            onClick={onSubmit}
          >
            {submitText}
          </Button>
          <Button
            className="close-btn w-full"
            onClick={onClose}
            variant="outlined"
          >
            {closeText}
          </Button>
        </div>
      </div>
    </Paper>
  );
}
