"use client";

import { Typography } from "@/app/components/ui";
import { BlogType } from "@/types/blog";
import React, { useEffect, useState } from "react";
import ButtonElement from "../Button";
import { RightValue } from "../Item";
import BlogCard from "./BlogCard";

type Props = {
  value: RightValue;
};

export default function LatestBlogElement({ value }: Props) {
  //   const res = await axiosInstance({
  //     url: urlWithQueryParams(`/blog`, {
  //       per_page: 3,
  //     }),
  //   });

  const [blogItems, setBlogItems] = useState([]);

  useEffect(() => {
    getBlog();
  }, []);

  async function getBlog() {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/blog?per_page=3`
      //   { cache: "force-cache" }
    );
    const result = await res.json();
    setBlogItems(result.data);
  }

  return (
    <div className="flex flex-col gap-y-8">
      <Typography variant="h2" className="text-2xl font-bold">
        {value?.title || "آخرین مطالب بلاگ"}
      </Typography>
      <div className="flex flex-col items-center w-full gap-y-10">
        <div className="grid grid-cols-12 w-full gap-y-8 md:gap-x-8">
          {blogItems?.slice(0, 3).map((blog) => {
            return (
              <div
                key={blog.id}
                className="col-span-12 md:col-span-6 lg:col-span-4"
              >
                <BlogCard item={blog} />
              </div>
            );
          })}
        </div>
        <ButtonElement
          value={{
            text: "مطالب بیشتر",
            link: process.env.NEXT_PUBLIC_BLOG_URL ?? "#",
          }}
        />
      </div>
    </div>
  );
}
