"use client"

import React, { ReactNode, useEffect } from "react"

type TransformOriginType = "bottom" | "top"

interface Props {
  isOpen?: boolean
  anchorElement: HTMLDivElement | null
  transformOrigin?: TransformOriginType
  handleClose: () => void
  children: ReactNode
  id?: string
  width?: string | number
}

const Popover = ({
  isOpen,
  anchorElement,
  transformOrigin = "top",
  children,
  id = "sample-popover",
  handleClose,
  width = 100,
}: Props) => {
  let currentView = null

  if (!!!children) return null

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (anchorElement && !anchorElement.contains(event.target as Node)) {
        handleClose()
      }
    }
    if (isOpen) {
      document.addEventListener("click", handleDocumentClick)
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [isOpen, handleClose, anchorElement])

  if (!!isOpen && !!anchorElement) {
    currentView = (
      <div
        aria-labelledby={id}
        className="absolute z-50 bg-white border border-black/[.08] rounded-lg shadow-md"
        style={{
          transformOrigin,
          top: `${anchorElement.offsetTop + anchorElement.offsetHeight}px`,
          direction: "rtl",
          right: 0,
          width,
        }}
      >
        {children}
      </div>
    )
  }

  return <>{currentView}</>
}

export default Popover
