import { AttachmentType } from "@/app/types/attachment";
import { ElementType } from "@/app/types/element";
import React from "react";
import TitleSubTitle from "../../../TitleSubTitle";
import { RightValue } from "../Item";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function TitleSubTitleElement({ item, value }: Props) {
  const icon: AttachmentType | null = value?.icon;

  const title = value?.title;

  const subtitle = value?.subtitle;

  return <TitleSubTitle title={title} subtitle={subtitle} icon={icon} />;
}
