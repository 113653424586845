import { ElementType } from "@/app/types/element";
import { isJson, RightValue } from "../Item";
import Timeline from "../../../Timeline";
import React from "react";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function TimelineElement({ item, value }: Props) {
  let items = value?.items ?? [];

  try {
    items = items?.map((x) => {
      let image = x?.image ?? undefined;

      try {
        if (isJson(image)) image = JSON.parse(x.image);
      } catch (e) {}

      return {
        ...x,
        image,
      };
    });
  } catch (e) {}

  return <Timeline items={items} />;
}
