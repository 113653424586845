import moment from "jalali-moment"

const getDateFormat = (
  date: string,
  format: string = "YYYY/MM/DD",
  locale: string = "fa"
) => {
  return moment(date).locale(locale).format(format)
}

export default getDateFormat
