import { ElementType } from "@/app/types/element";
import TypeItem, { RightValue, isJson } from "../Item";
import Tabs from "../../../Tabs";
import React from "react";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function TabElement({ item, value }: Props) {
  let tabItemsNode = [];
  if (value?.items?.length > 0) {
    tabItemsNode = value.items.map((item) => {
      let targetItem = item?.component;
      if (isJson(targetItem)) {
        targetItem = JSON.parse(targetItem);
      }

      return {
        title: item?.title,
        children: (
          <TypeItem
            item={{
              item: targetItem?.component,
              value: targetItem?.value,
            }}
          />
        ),
      };
    });
  }

  return (
    <Tabs
      items={tabItemsNode}
      className='[&_.tabs]:mb-4 [&_.tabs]:border-b-transparent [&_.tab-item.active-tab]:after:rounded-none [&_.tab-item.active-tab]:after:h-[4px] [&_.tab-item.active-tab>span]:text-black/[.87] [&_.tab-item>span]:text-xl [&_.tab-item.active-tab>span]:font-bold'
    />
  );
}
