import { getOptimizedMediaUrl } from "@/app/utils";
import { TimelineItemType } from "..";
import { Typography } from "@/app/components/ui";
import RenderHtml from "../../RenderHtml";
import React from "react"

type Props = {
  item: TimelineItemType;
};
export default function SwiperTimelineItem({ item }: Props) {
  return (
    <div className='flex flex-col items-center gap-y-4'>
      <div className='relative'>
        <img
          src={getOptimizedMediaUrl({
            mediaId: item?.image?.id,
          })}
          alt={item?.title ?? ""}
          className='w-auto h-[240px] max-w-full object-cover rounded-md'
        />
        {!!item?.year && (
          <Typography className='text-white absolute bottom-2 left-2 font-extrabold text-4xl'>
            {item.year}
          </Typography>
        )}
      </div>
      <Typography className='text-2xl font-bold text-center text-darkPrimary'>
        {item?.title ?? ""}
      </Typography>
      <RenderHtml html={item?.desc ?? ""} className='text-center' />
    </div>
  );
}
