"use client";

import { useEffect, useState } from "react";

const useResize = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    function resizeHandler() {
      setWindowWidth(window.innerWidth);
    }

    resizeHandler();

    window.removeEventListener("resize", resizeHandler);
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return windowWidth;
};
export default useResize;
