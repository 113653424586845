"use client";

import React, { useCallback, useEffect, useState } from "react";
import { Radio, Typography } from ".";

type RadioItemType = {
  label: string;
  value: string;
};

type Props = {
  label?: string;
  className?: string;
  items: RadioItemType[];
  value?: string;
  onChange?: (value: string) => void;
  error?: boolean;
  helperText?: string;
};

export default function RadioGroup({
  label,
  className = "",
  items = [],
  value,
  onChange,
  error,
  helperText,
}: Props) {
  const [radioValue, setRadioValue] = useState<string | null>(null);
  useEffect(() => {
    setRadioValue(value || null);
  }, [value]);

  const radioValueChangeHandler = useCallback((val: string) => {
    if (onChange) onChange(val);
    setRadioValue(val);
  }, []);

  let helperTextClass = "text-[14px] text-black/[.6]";
  if (error) {
    //Change helper text class
    helperTextClass = "text-[14px] text-red-500";
  }

  if (items.length === 0) return null;

  return (
    <div className="flex flex-col gap-y-2">
      <div className={`flex flex-row items-center gap-x-12 ${className}`}>
        {!!label && <Typography>{label}</Typography>}
        <div className="radio-items flex flex-row gap-x-8">
          {items.map((item, index) => (
            <Radio
              key={index}
              value={item.value}
              label={item.label}
              checked={radioValue === item.value}
              onSelect={radioValueChangeHandler}
              placement="Left"
            />
          ))}
        </div>
      </div>
      {helperText && (
        <Typography className={`flex mt-2 ${helperTextClass}`} variant="span">
          {helperText}
        </Typography>
      )}
    </div>
  );
}
