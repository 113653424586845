"use client";

import { ElementType } from "@/app/types/element";
import { RightValue } from "../Item";
import { Button } from "@/app/components/ui";
import React from "react";

type Props = {
  item?: ElementType;
  value?: RightValue;
};

export default function ButtonElement({ value }: Props) {
  const href = value?.link ?? "#";
  const title = value?.text ?? "";
  const className = value?.class ?? "";
  const variant = value?.variant ?? "contained";

  return (
    <a href={href}>
      <Button variant={variant} className={className}>
        {title}
      </Button>
    </a>
  );
}
