"use client"

import React, {
    ReactNode,
    useState
} from "react"
import { ChevronLeftIcon, ExpandLessIcon } from "../../icons"
import IconButton from "../IconButton"
import Paper from "../Paper"
import Typography from "../Typography"

export type AccordionType = {
  title: string
  icon?: ReactNode
  children?: ReactNode
  onClick?: () => void
}

export type Props = {
  accordion: AccordionType
  className?: string
}

const Accordion = ({ accordion, className }: Props) => {
  const { title, icon, children, onClick } = accordion
  const [open, setOpen] = useState(false)

  let expandIcon = <ChevronLeftIcon color="rgba(142, 142, 142, 1)" />

  if (!!open) {
    expandIcon = <ExpandLessIcon color="rgba(142, 142, 142, 1)" />
  }

  let showChildren = !!open && !!children

  const onAccordionAction = (e: any) => {
    if (onClick) {
      onClick()
    } else {
      setOpen((crt) => !crt)
    }
  }

  return (
    <Paper hasShadow={false} hasBorder={false} className={`${className}`}>
      <div
        onClick={onAccordionAction}
        className="flex items-center justify-between w-full p-2 px-3 cursor-pointer "
      >
        <div className="flex items-center gap-x-4">
          {icon && icon}
          <Typography variant="span">{title}</Typography>
        </div>
        <IconButton>{expandIcon}</IconButton>
      </div>
      {showChildren && children}
    </Paper>
  )
}

export default Accordion
