"use client";

import {
  ClipboardEvent,
  InputHTMLAttributes,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { TextInput } from "../ui";

interface OtpHandlerProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  length: number;
  onChange?: (otp: string) => void;
  onCompleted?: (otp: string) => void;
  error?: boolean;
  helperText?: string;
  className?: string;
  disabled?: boolean;
}

const OtpHandler = (props: OtpHandlerProps & any) => {
  const {
    length,
    onChange,
    onCompleted,
    disabled = false,
    error = false,
    helperText,
    ...rest
  } = props;
  const [otp, setOTP] = useState<string[]>(Array(length).fill(""));
  const refs = useRef<(HTMLInputElement | null)[]>([]);
  const lastRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    refs.current[0]?.focus();
  }, []);

  const handleChange = (index: number, value: string) => {
    const newOTP = [...otp];
    if (/^\d$/.test(value)) {
      newOTP[index] = value;
    } else {
      newOTP[index] = "";
    }
    setOTP(newOTP);
    if (value === "" && index > 0) {
      refs.current[index - 1]?.focus();
    } else if (index < length - 1 && value !== "") {
      refs.current[index + 1]?.focus();
    }
    if (newOTP.every((v) => v !== "")) {
      onCompleted?.(newOTP.join(""));
    }
    if (onChange) onChange(newOTP.join(""));
  };

  const handleKeyDown = (
    index: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      refs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pasteData = event.clipboardData
      .getData("text/plain")
      .slice(0, length);
    const newOTP = [...otp];
    pasteData.split("").forEach((value, index) => {
      if (/^\d$/.test(value)) {
        newOTP[index] = value;
      }
    });
    setOTP(newOTP);
    refs.current[pasteData.length]?.focus();
    if (newOTP.every((v) => v !== "")) {
      onCompleted?.(newOTP.join(""));
    }
    if (onChange) onChange(newOTP.join(""));
  };

  let helperTextClass = "text-[14px] text-black/[.6]";
  if (error) {
    //Change helper text class
    helperTextClass = "text-[14px] text-red-500";
  }

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={`flex flex-col gap-y-1 ${props?.className || ""}`}>
      <div className="flex flex-row items-center gap-x-4" dir="ltr">
        {otp.map((value, index: number) => (
          <div
            key={index}
            className={`relative otp-input ${
              activeIndex === index ? "active" : ""
            }`}
          >
            <TextInput
              ref={(el) => {
                refs.current[index] = el;
                if (index === length - 1) {
                  lastRef.current = el;
                }
              }}
              inputProps={{
                disabled: disabled,
                maxLength: 1,
                style: {
                  textAlign: "center",
                  padding: 10,
                },
                onChange: (event: any) =>
                  handleChange(index, event.target.value),
                onKeyDown: (event: any) => handleKeyDown(index, event),
                onPaste: handlePaste,
                value: value,
                type: "number",
                onFocus: () => setActiveIndex(index),
                ...rest,
              }}
              error={error}
            />
          </div>
        ))}
      </div>
      {helperText && (
        <span className={`flex mt-2 ${helperTextClass}`}>{helperText}</span>
      )}
    </div>
  );
};

export default OtpHandler;
