"use client"
import { IconButton, Typography } from "@/app/components/ui"
import React, { ReactNode, useCallback } from "react"
import BackIcon from "../icons/Back"

type Props = {
  title: string
  children: ReactNode
  icon?: ReactNode
  endHeaderNode?: ReactNode
  afterNode?: ReactNode
  beforeHeaderNode?: ReactNode
  afterHeadingNode?: ReactNode
  className?: string
  titleClassName?: string
  titleVariant?: string
  onIconClick?: () => void
}

export default function BoxHeader({
  title,
  children,
  endHeaderNode,
  icon = <BackIcon />,
  onIconClick,
  className,
  titleClassName,
  afterNode,
  afterHeadingNode,
  beforeHeaderNode,
  titleVariant = "p",
}: Props) {
  const onIconClickHandler = useCallback(() => {
    if (onIconClick) onIconClick()
  }, [onIconClick])

  return (
    <div className={`flex flex-col w-full gap-y-4 ${className || ""}`}>
      <div className="relative parent-title-class w-full">
        {!!endHeaderNode && endHeaderNode}
        {title && (
          <div
            className={`heading sticky w-full top-0 z-[1000] bg-white flex flex-row justify-center items-center gap-x-2 border-b-4 border-black/[.03] p-3 ${
              titleClassName || ""
            }`}
          >
            {!!beforeHeaderNode && beforeHeaderNode}
            {!!onIconClick && (
              <IconButton
                className="back-icon absolute top-[50%] translate-y-[-50%] right-3"
                onClick={onIconClickHandler}
              >
                {icon}
              </IconButton>
            )}
            <Typography
              variant={titleVariant as any}
              className="box-header-title font-medium"
            >
              {title}
            </Typography>
            {!!afterNode && afterNode}
          </div>
        )}
        {!!afterHeadingNode && afterHeadingNode}
      </div>
      <div className="main-layout">{children}</div>
    </div>
  )
}
