"use client";

import { useClient } from "@/app/hooks";
import { ElementType } from "@/app/types/element";
import React, { useCallback, useState } from "react";
import SwiperSlider from "../../../SwiperSlide";
import { isJson, RightValue } from "../Item";
import ReelItem from "./ReelItem";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function ReelsElement({ item, value }: Props) {
  const { isClient } = useClient();

  const [selected, setSelected] = useState<number>(-1);
  const handleSelectReelItem = useCallback(
    (index: number) => {
      setSelected(selected === index ? -1 : index);
    },
    [selected]
  );

  const reelItems = value?.items?.map((reel, key) => {
    let cover: any = reel?.cover ?? {};
    let video: any = reel?.video ?? {};
    try {
      if (cover && isJson(cover)) cover = JSON.parse(cover ?? "{}");
      if (video && isJson(video)) video = JSON.parse(video ?? "{}");
    } catch (e) {}

    return {
      id: cover.id,
      thumbnail: process.env.NEXT_PUBLIC_API_URL + "/media/" + cover.id || "",
      src: process.env.NEXT_PUBLIC_API_URL + "/media/" + video?.id || "",
    };
  });

  const sliderItems = reelItems.map((item, key) => {
    let clss = "";

    if (key === 0) clss += ` rounded-tr-xl rounded-br-xl`;

    if (reelItems.length - 1 === key) clss += ` rounded-tl-xl rounded-bl-xl`;
    return (
      <ReelItem
        onSelect={() => handleSelectReelItem(key)}
        onDeselect={() => setSelected(-1)}
        isSelected={selected === key}
        {...item}
        key={key}
        className={clss}
      />
    );
  });

  if (!isClient) return;

  return (
    <SwiperSlider
      config={{
        slidesPerView: 3.2,
        spaceBetween: 2,
        breakpoints: {
          100: {
            slidesPerView: 2.2,
          },
          992: {
            slidesPerView: 3.2,
          },
        },
      }}
      items={sliderItems}
      className="[&_.slider-nav-btn]:!hidden"
    />
  );
}
