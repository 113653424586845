import SharedList from "../../../MenuList";
import TitleEl from "../../../TitleEl";
import { RightValue } from "../Item";
import React from "react";

type Props = {
    value: RightValue;
};

export default function ListElement({ value }: Props) {
    const title = value?.list_title;
    const title_class = value?.list_title_class;
    const items = value?.items ?? [];

    return (
        <SharedList
            title={title}
            menus={items?.map((item) => ({
                title: item?.title ?? "",
                href: item?.link ?? "#",
            }))}
        />
    );
}
