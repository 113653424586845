const querystring = require("querystring");

const getQueryParams = (obj: object) => querystring.stringify(obj);

function urlWithQueryParams(url: string, object: any) {
  if (typeof object !== "object") return "";

  if (!url) return "";

  const params = getQueryParams(object);
  const hasParams = Object.keys(params).length > 0;

  return `${url}${hasParams ? `?${params}` : ``}`;
}
export default urlWithQueryParams;
