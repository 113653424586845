import React from "react";
import TypeItem, { isJson, RightValue } from "../Item";

type Props = {
    value: RightValue;
};

export default function ButtonGroupHorizElement({ value }: Props) {
    const buttons = value?.items ?? [];
    if (buttons.length === 0) return null;

    return (
        <>
            <div
                className={`flex flex-col md:flex-row gap-4 [&_button]:w-full md:[&_button]:w-auto`}
            >
                {buttons?.map((item, key) => {
                    let targetItem = item?.component ?? {};

                    try {
                        if (isJson(targetItem))
                            targetItem = JSON.parse(item?.component ?? "{}");
                    } catch (e) { }

                    if (targetItem)
                        return (
                            <TypeItem
                                key={key}
                                item={{
                                    item: targetItem?.component,
                                    value: targetItem?.value,
                                }}
                            />
                        );
                })}
            </div>
        </>
    );
}
