import React from "react";

type Props = {
    height?: number;
    backgroundColor?: string;
    className?: string;
};
export default function Divider({
    height = 2,
    backgroundColor = "rgba(0,0,0,0.06)",
    className = "",
}: Props) {
    return (
        <div
            className={`w-full flex ${className}`}
            style={{ height, backgroundColor }}
        ></div>
    );
}
