import React, { HTMLAttributes, ReactNode } from "react";

type Props = {
  children: ReactNode
  className?: string;
  hasShadow?: boolean;
  hasBorder?: boolean;
};
export default function Paper({
  children,
  className,
  hasShadow = true,
  hasBorder = true,
  ...rest
}: Props & HTMLAttributes<HTMLDivElement>) {
  let clssName = "bg-white p-6 rounded-lg shadow-md shadow-black/[.08]";

  if (className) clssName += ` ${className}`;

  if (!hasShadow) clssName += ` !shadow-none`;

  if (!!hasBorder) clssName += ` border border-black/[.12]`;

  if (!children) return null;

  return (
    <div {...rest} className={clssName}>
      {children}
    </div>
  );
}
