"use client";

import { ElementType } from "@/app/types/element";
import { EntityVertical } from "../../..";
import { RightValue } from "../Item";
import useSWR from "swr";
import { urlWithQueryParams } from "@/app/utils";
import { WikiType } from "@/app/types/wiki";
import moment from "jalali-moment";
import axiosInstance from "@/api/axios";
import React from "react";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function WikiVerticalElement({ item, value }: Props) {
  const { data, isLoading } = useSWR("/wiki/sidebar", async () => {
    const res = await axiosInstance({
      url: urlWithQueryParams(`/wiki/sidebar`, {
        per_page: 4,
      }),
    });
    return res?.data?.data;
  });

  const wikiItems: WikiType[] = !!data ? data : [];

  if (isLoading || data === undefined) return null;

  const moreLink = value?.more ?? "#";

  return (
    <EntityVertical
      moreLink={moreLink}
      moreText='مشاهده ویکی وحدت'
      items={wikiItems?.map((x) => ({
        title: x.title,
        href: `/fa/wiki/${x.slug}`,
        subtitle: moment(x.created_at).format("jYYYY-jMM-jDD"),
      }))}
      className='[&_.post]:relative [&_.post]:!pr-3 hover:[&_.post]:before:bg-primary [&_.post]:before:absolute [&_.post]:before:transition-all [&_.post]:before:rounded-md [&_.post]:before:right-[-8px] [&_.post]:before:top-0 [&_.post]:before:bottom-0 [&_.post]:before:w-[4px] [&_.post]:before:bg-black/10'
      hasTumbnail={false}
    />
  );
}
