"use client";

import axiosInstance from "@/api/axios";
import { useLoading } from "@/app/hooks";
import { ProductSiteType } from "@/app/types/product-site";
import { shouldLoginToast } from "@/app/utils";
import { useRouter } from "@bprogress/next";
import { usePathname } from "next/navigation";
import React, { ReactNode, useEffect, useState } from "react";
import { toast } from "sonner";
type Props = {
  productSite: ProductSiteType;
  isChecked?: boolean;
  isAuthenticated?: boolean;
  render: (toggle: () => void, isChecked, isLoading) => ReactNode;
  onToggled?: () => void;
};
export default function ToggleProductSiteLike({
  productSite,
  isChecked,
  isAuthenticated,
  render,
  onToggled,
}: Props) {
  const [isFavorite, setIsFavorite] = useState(false);
  useEffect(() => {
    setIsFavorite(isChecked ?? false);
  }, [isChecked]);

  const { startLoading, stopLoading, isLoading } = useLoading();
  const router = useRouter()
  const pathname = usePathname()
  const toggleFavorite = async () => {
    if (!isAuthenticated) return shouldLoginToast(router, pathname);

    startLoading();
    try {
      startLoading();
      await axiosInstance({
        url: "/user/favorite/toggle",
        method: "POST",
        data: {
          site_product_id: productSite.id,
        },
      });
      const newFavoriteState = !isFavorite;
      setIsFavorite(newFavoriteState);
      toast.success(
        newFavoriteState
          ? "با موفقیت به علاقه‌مندی ها اضافه گردید"
          : "با موفقیت از علاقه‌مندی ها حذف گردید"
      );

      if (onToggled) onToggled();

      stopLoading();
    } catch (e) {
      stopLoading();
    }
  };

  return <>{render(toggleFavorite, isFavorite, isLoading)}</>;
}
