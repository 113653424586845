import { ElementType } from "@/app/types/element"
import { RightValue } from "../Item"
import { AttachmentType } from "@/app/types/attachment"
import { getOptimizedMediaUrl, urlWithQueryParams } from "@/app/utils"
import ProductCollectionSwiperNew from "../../../ProductCollectionSwiperNew"
import axiosInstance from "@/api/axios"
import React from "react";

type Props = {
  item: ElementType
  value?: RightValue
}

let keysHaveArrayValue = ["brands"]

const getProducts = async (query: object = {}) => {
  const res = await axiosInstance({
    url: urlWithQueryParams(`/product/site`, {
      ...query,
    }),
  })
  return res?.data
}

export default async function SwiperProductsElement({ item, value }: Props) {
  const icon: AttachmentType | null = value?.icon ?? null
  const title = value?.title ?? ""
  const btnText = value?.btn_text ?? ""
  const btnLink = value?.btn_link ?? ""
  const bgColor = value?.bg_color ?? undefined
  const titleColor = value?.title_color ?? undefined
  const brand = value?.brand ?? null
  const category = value?.category ?? null
  const queryString = value?.query_string ?? null

  let finalActiveParams: { [key: string]: string } = {}

  if (queryString) {
    const queryParamsAsArray = queryString?.split("?")?.[1]?.split("&") ?? []
    if (queryParamsAsArray?.length > 0) {
      for (let keyValueString of queryParamsAsArray) {
        const keyValueArray = keyValueString?.split("=")
        const key = keyValueArray?.[0]
        let value = keyValueArray?.[1]

        if (keysHaveArrayValue.includes(key)) value = `[${value}]`

        finalActiveParams[key] = value
      }
    }
  }

  const res = await getProducts(finalActiveParams)
  const products = res?.data ?? []

  return (
    <>
      <ProductCollectionSwiperNew
        products={products}
        title={title}
        iconUrl={
          icon
            ? getOptimizedMediaUrl({
                mediaId: icon?.id,
                width: 205,
                height: 205,
              })
            : ""
        }
        btnLink={btnLink}
        btnText={btnText}
        bgColor={bgColor}
        headingTitleColor={titleColor}
      />
    </>
  )
}
