import React from "react";

import { Typography } from ".";
import { VahdatImage } from "../shared";

type Props = {
    src?: string;
    alt?: string;
    className?: string;
};

export default function Avatar({ src, alt, className }: Props) {
    let content = null;

    if (alt)
        content = (
            <Typography className='font-medium text-base'>{alt?.[0]}</Typography>
        );

    if (src)
        content = (
            <VahdatImage
                fill
                src={src}
                alt={alt || ""}
                style={{
                    objectFit: "cover",
                    objectPosition: "center",
                }}
            />
        );

    if (!alt && !src) return null;

    return (
        <div
            className={`relative overflow-hidden flex flex-col justify-center items-center w-[64px] h-[64px] rounded-full bg-black/[.06] ${className || ""
                }`}
        >
            {content}
        </div>
    );
}
