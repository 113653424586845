import Link from "next/link"
import React from "react"
import { Button } from "../ui"

type Props = {}

const BackToHomeButton = (props: Props) => {
  return (
    <Link href={`/fa`} prefetch={false} title="بازگشت به صفحه اصلی">
      <Button variant="contained">بازگشت به صفحه اصلی</Button>
    </Link>
  )
}

export default BackToHomeButton
