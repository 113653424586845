"use client"

import { PauseIcon, PlayIcon } from "@/app/components/icons"
import { Colors } from "@/app/const/colors"
import React, { useCallback, useEffect, useRef } from "react"

type Props = {
    thumbnail: string
    src: string
    onSelect: () => void
    onDeselect: () => void
    isSelected?: boolean
    className?: string
}

const ReelItem = ({
    thumbnail,
    src,
    onSelect,
    onDeselect,
    isSelected = false,
    className,
}: Props) => {
    let contentClss =
        "w-full flex items-center justify-center h-full bg-black/[0.3] object-cover absolute top-0 left-0 right-0 bottom-0"

    const videoRef = useRef<HTMLVideoElement>(null)

    const selectContentHandler = useCallback(() => {
        if (typeof window === undefined) return

        if (window?.innerWidth < 768) return
        if (onSelect && !isSelected) {
            onSelect()
        }
    }, [onSelect, isSelected, window])

    useEffect(() => {
        if (videoRef?.current) videoRef?.current?.play()
    }, [videoRef?.current])

    const deSelectContentHandler = useCallback(() => {
        if (typeof window === undefined) return
        if (window?.innerWidth < 768) return
        onDeselect()
    }, [onDeselect, window])

    let reelContent = (
        <div
            style={{
                background: `url(${thumbnail})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
            }}
            className={contentClss}
        ></div>
    )

    if (!!isSelected) {
        reelContent = (
            <video
                src={src}
                controls={false}
                className={contentClss}
                autoPlay={true}
                // loop={true}
                ref={videoRef}
            />
        )
    }

    return (
        <div
            onClick={selectContentHandler}
            onMouseOver={selectContentHandler}
            onMouseOut={deSelectContentHandler}
            className={`h-[400px] relative overflow-hidden [&_.overlay]:hover:flex [&_.overlay]:hover:md:hidden cursor-pointer w-full ${className ?? ""
                }`}
        >
            {reelContent}
            {!isSelected && (
                <div
                    onClick={() => {
                        if (!isSelected) onSelect()
                    }}
                    className="overlay flex md:!hidden absolute items-center justify-center top-0 bottom-0 right-0 left-0 w-full h-full bg-black/[0.5]"
                >
                    {isSelected ? (
                        <PauseIcon size={60} color={Colors.white} />
                    ) : (
                        <PlayIcon size={60} color={Colors.white} />
                    )}
                </div>
            )}
        </div>
    )
}

export default ReelItem
