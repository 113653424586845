import { ElementType } from "@/app/types/element";
import TypeItem, { RightValue, isJson } from "../Item";
import Tabs from "../../../Tabs";
import { Space } from "@/app/components/ui";
import React from "react";

type Props = {
    item: ElementType;
    value?: RightValue;
};

export default function SpaceElement({ item, value }: Props) {
    if (value?.height === undefined && typeof +value?.height === "number")
        return null;

    return <Space height={+value?.height} />;
}
