"use client"

import React, { useCallback, useEffect, useState } from "react"
import { IconButton, Typography } from "../ui"
import { MinusIcon, PlusIcon } from "../icons"

type Props = {
  defaultVal?: number

  onChange?: (val: number) => void
  onIncrement?: (val: number) => void
  onDecrement?: (val: number) => void
  min?: number
  max?: number
  disabled?: boolean
}
export default function QtyManager({
  defaultVal,
  onChange,
  onIncrement,
  onDecrement,
  min = 0,
  max = 10000000,
  disabled = false,
}: Props) {
  const [val, setVal] = useState(1)
  useEffect(() => {
    if (defaultVal !== undefined) setVal(defaultVal)
  }, [defaultVal])

  const minusHandler = useCallback(() => {
    setVal((crt) => {
      const nVal = crt > min ? crt - 1 : min
      if (onDecrement && nVal !== crt) onDecrement(nVal)
      if (onChange && nVal !== crt) onChange(nVal)
      return nVal
    })
  }, [onChange, onDecrement, min])

  const pluseHandler = useCallback(() => {
    setVal((crt) => {
      const nVal = crt < max ? crt + 1 : max
      if (onIncrement && nVal !== crt) onIncrement(nVal)
      if (onChange && nVal !== crt) onChange(nVal)
      return nVal
    })
  }, [onChange, onIncrement, max])

  return (
    <div className="qty-manager flex flex-row justify-between items-center gap-x-4 py-2 px-4 border border-black/[.1] rounded-lg">
      <IconButton
        className="!w-7 !h-7"
        disabled={disabled || val === max}
        onClick={pluseHandler}
      >
        <PlusIcon size={18} />
      </IconButton>
      <Typography>{val}</Typography>
      <IconButton
        className="!w-7 !h-7"
        disabled={disabled}
        onClick={minusHandler}
      >
        <MinusIcon size={18} />
      </IconButton>
    </div>
  )
}
