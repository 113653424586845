"use client"

import { AttachmentType } from "@/app/types/attachment"
import React, { useState } from "react"
import Line from "./Line"
import SelectedPreview from "./SelectedPreview"
import ShowOnDevices from "../ShowOnDevices"
import { deviceName } from "@/app/const/device"
import SwiperTimeline from "./Swiper"

export type TimelineItemType = {
  year: number
  title: string
  desc: string
  image: AttachmentType
}

type Props = {
  items: TimelineItemType[]
}

export default function Timeline({ items = [] }: Props) {
  const [selectedTimeline, setSelectedTimeline] = useState<TimelineItemType>(
    items?.[0]
  )

  return (
    <div className="flex flex-col min-h-[350px] py-8 justify-end">
      <ShowOnDevices devices={[deviceName.Desk, deviceName.Tablet]}>
        {!!selectedTimeline && <SelectedPreview item={selectedTimeline} />}
        <Line
          items={items}
          selected={selectedTimeline}
          onSelect={setSelectedTimeline}
        />
      </ShowOnDevices>
      <ShowOnDevices devices={[deviceName.Mobile]}>
        <SwiperTimeline
          items={items}
          onSelect={setSelectedTimeline}
          selected={selectedTimeline}
        />
      </ShowOnDevices>
    </div>
  )
}
