import { ElementType } from "@/app/types/element"
import { RightValue } from "../Item"
import { Button, Typography } from "@/app/components/ui"
import Link from "next/link"
import React from "react";

type Props = {
  item: ElementType
  value?: RightValue
}

export default function TitleDescBtnElement({ value }: Props) {
  //Title
  const title = value?.title
  const desc = value?.desc

  //Link
  const btn_link = value?.btn_link
  const btn_text = value?.btn_text

  return (
    <div
      className={`relative max-w-full flex flex-col h-full justify-center p-6 md:p-8 title-desc-element ${
        value?.class ?? ""
      }`}
    >
      <div className="col-holder flex flex-col gap-y-2 md:gap-y-6">
        <Typography variant="h2" className="text-2xl md:text-[32px] title">
          {title}
        </Typography>
        {!!desc && (
          <Typography className="!text-black/60 text-base desc" variant="p">
            {desc}
          </Typography>
        )}
        {!!btn_link && btn_text && (
          <Link href={btn_link} title={title} prefetch={false}>
            <Button className="btn">{btn_text}</Button>
          </Link>
        )}
      </div>
    </div>
  )
}
