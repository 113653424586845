"use client";
import { useDevice } from "@/app/hooks";
import Link from "next/link";
import React from "react";
import { TelegramIcon, WhatsAppIcon, YouTubeIcon } from "../../icons";
import InstagramIcon from "../../icons/InstagramIcon";

type Props = {
  className?: string;
};

export default function Socials({ className = "" }: Props) {
  const device = useDevice();
  const isMobile = device === "mobile";

  return (
    <div className="flex flex-row gap-x-4">
      {[
        {
          title: "وحدت در یوتیوب",
          icon: <YouTubeIcon size={isMobile ? 26 : 32} />,
          link: "https://www.youtube.com/@vahdatoptic",
        },
        {
          title: "وحدت در تلگرام",
          icon: <TelegramIcon size={isMobile ? 26 : 32} />,
          link: "https://t.me/Vahdatoptic1",
        },
        {
          title: "وحدت در واتس‌اپ",
          icon: <WhatsAppIcon size={isMobile ? 26 : 32} />,
          link: "https://wa.me/+989104443316",
        },
        {
          title: "وحدت در اینستاگرام",
          icon: <InstagramIcon size={isMobile ? 26 : 32} />,
          link: "https://www.instagram.com/vahdatoptic/",
        },
      ].map((item, index) => (
        <Link
          key={index}
          href={item.link}
          prefetch={false}
          className={`[&_svg]:opacity-[.38] hover:[&_svg]:opacity-[1] ${className}`}
          target="_blank"
        >
          {item?.icon}
        </Link>
      ))}
    </div>
  );
}
