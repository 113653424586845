import { AttachmentType } from "@/app/types/attachment"
import { CollectionType } from "@/app/types/collections"
import { ElementType } from "@/app/types/element"
import { getOptimizedMediaUrl } from "@/app/utils"
import React from "react"
import ProductCollectionSwiperNew from "../../../ProductCollectionSwiperNew"
import { RightValue } from "../Item"
import { MediaType } from "@/app/types/media"

type Props = {
    item: ElementType
    value?: RightValue
}

export default async function CollectionElement({ item, value }: Props) {
    if (!value?.collection_id?.id) return null

    //   const res = await axiosInstance({
    //     url: `/collections/${value?.collection_id?.id}`,
    //   })
    const res = await fetch(process.env.NEXT_PUBLIC_API_URL + `/collections/${value?.collection_id?.id}`, { next: { revalidate: 0 } }).then(r => r.json())
    const collection: CollectionType = res?.data

    const icon: MediaType | null = collection?.image ?? null
    const title = collection?.title ?? ""
    const bgColor = collection?.background_color ?? undefined
    const titleColor = collection?.text_color ?? undefined
    const brand = collection?.brand ?? null

    const products = collection?.site_products ?? []

    return (
        <>
            <ProductCollectionSwiperNew
                products={products}
                title={title}
                iconUrl={icon ? getOptimizedMediaUrl({ mediaId: icon.id }) : ""}
                btnLink={`/fa/collection/${collection.slug}`}
                btnText={`مشاهده همه محصولات`}
                bgColor={bgColor}
                headingTitleColor={titleColor}
            />
        </>
    )
}
