import { AttachmentType } from "@/app/types/attachment";
import { getOptimizedMediaUrl } from "@/app/utils";
import Link from "next/link";
import React from "react"

export type GridImageType = {
  title: string;
  href: string;
  alt?: string;
  image: AttachmentType;
};

type Props = {
  items: GridImageType[];
  col_span_lg?: number;
  col_span_md?: number;
  col_span: number;
  className?: string;
};
export default function GridImages({
  items = [],
  col_span_lg,
  col_span_md,
  col_span,
  className,
}: Props) {
  if (items.length === 0) return null;

  return (
    <div className={`grid grid-cols-12 gap-8 ${className ?? ""}`}>
      {items.map((item, key) => {
        const colSpanInDesktop = col_span_lg ?? 4;
        const colSpanInTablet = col_span_md ?? colSpanInDesktop;

        const alt = item?.alt ?? "";

        if (item?.image === undefined) return null;

        const imageSource = item?.image?.id
          ? getOptimizedMediaUrl({ mediaId: item.image.id })
          : null;

        if (!imageSource) return;

        return (
          <div
            className={`col-span-6 md-col-span-${
              colSpanInTablet || colSpanInDesktop
            } lg-col-span-${colSpanInDesktop}`}
            key={key}
          >
            <Link
              prefetch={false}
              className='flex flex-col items-center justify-center hover:bg-black/[.04] transition-all rounded-xl'
              href={item?.href ?? "#"}
            >
              <img
                src={getOptimizedMediaUrl({
                  mediaId: item?.image?.id,
                })}
                alt={alt ?? item.title ?? ""}
              />
            </Link>
          </div>
        );
      })}
    </div>
  );
}
