"use client";

interface Props {
  className?: string;
  file: File | string;
  clickHandler?: () => void;
}

const FileViewer = ({ className, file, clickHandler }: Props) => {
  let fileImage = null;

  try {
    fileImage = typeof file === "string" ? file : URL.createObjectURL(file);
  } catch (e) {}

  const onClickHandler = () => {
    if (clickHandler) {
      clickHandler();
      return;
    }
    window.open(fileImage, "_blank");
  };

  return (
    <div
      onClick={onClickHandler}
      style={{ backgroundImage: `url(${fileImage})` }}
      className={`uploaded-file w-full h-full min-h-[260px] cursor-pointer bg-cover bg-center ${className}`}
    />
  );
};

export default FileViewer;
