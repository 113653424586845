import TypeItem, { RightValue } from "../Item";
import React from "react";

type Props = {
    value: RightValue;
};

export default function ColElement({ value }: Props) {
    let clss = "flex flex-col w-full";
    if (value?.class) clss += ` ${value.class}`;

    return (
        <div className={clss}>
            {value?.columns?.map((x) => {
                return x?.map((item, keyX) => (
                    <TypeItem
                        key={keyX}
                        item={{ item: item?.component, value: item?.value }}
                    />
                ));
            })}
        </div>
    );
}
