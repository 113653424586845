import urlWithQueryParams from "./urlWithQueryParams";

type Props = {
  mediaId: string;
  width?: number | string;
  height?: number | string;
  action?: "thumbnail" | "cropThumbnail";
  background?: string; //Should be rgba
  quality?: number; //1 to 100
  opacity?: string; //0 to 1 - for instance 0.2
};

const getOptimizedMediaUrl = ({
  mediaId,
  action = "thumbnail",
  ...rest
}: Props) => {
  let url = urlWithQueryParams(process.env.NEXT_PUBLIC_API_URL + `/media/${mediaId}`, {
    ...rest,
  });

  return url;
};

export default getOptimizedMediaUrl;
