import { MediaType } from "@/app/types/media";
import { Typography } from "../ui";
import { getOptimizedMediaUrl } from "@/app/utils";
import React from "react"

type Props = {
  colorCode: string;
  className?: string;
  texture?: MediaType;
  label?: string;
};

const ColorBox = ({ texture, colorCode, className, label }: Props) => {
  let boxColorStyle: any = { backgroundColor: colorCode };

  if (texture)
    boxColorStyle = {
      backgroundImage: `url(${getOptimizedMediaUrl({ mediaId: texture?.id })})`,
    };

  return (
    <div className='flex flex-row gap-x-4 items-center'>
      <div
        style={boxColorStyle}
        className={`color-box w-6 h-6 bg-cover bg-center rounded-lg border border-solid border-black/[.1] ${
          className || ""
        }`}
      ></div>
      {!!label && <Typography>{label}</Typography>}
    </div>
  );
};

export default ColorBox;
