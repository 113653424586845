import React from "react"
import { getOptimizedMediaUrl } from "@/app/utils"
type Props = {
  width?: number
  height?: number
  fill?: boolean
  media?: any
  alt?: string
  title?: string
  className?: string
}
export default function ProductThumbnail({
  width = 210,
  fill = false,
  media,
  className = "",
  alt,
  title,
}: Props) {
  let src = null
  src = getOptimizedMediaUrl({
    mediaId: media?.id,
    action: "thumbnail",
    width: 294,
    height: 294,
  })

  if (media === undefined) {
    src = "/assets/placeholder-product.jpg"
  }

  return (
    <img
      className={`product-thumbnail m-auto md:!m-0 min-w-[180px] min-h-[180px] !object-contain ${className}`}
      {...(!fill && { width })}
      src={src}
      alt={alt ?? ""}
      title={title ?? ""}
    />
  )
}
