"use client";

import React, { ReactNode, useEffect, useState } from "react";

import { IconButton } from "../ui";

interface Props {
  icon: ReactNode;
  activeIcon: ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  onClick?: (newState: boolean) => void;
}

const ToggleActionBtn = ({
  icon,
  activeIcon,
  isDisabled,
  isChecked,
  onClick,
}: Props) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (isChecked !== undefined) setChecked(isChecked);
  }, [isChecked]);

  const toggleActionHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const nValue = !checked;
    setChecked(nValue);
    if (onClick) onClick(nValue);
  };

  let currentIcon = <>{icon}</>;

  if (!!checked) {
    currentIcon = <>{activeIcon}</>;
  }

  if (!!!icon || !!!activeIcon) return null;

  return (
    <IconButton
      type='button'
      disabled={isDisabled}
      onClick={toggleActionHandler}
    >
      {currentIcon}
    </IconButton>
  );
};

export default ToggleActionBtn;
