import { Typography } from "@/app/components/ui";
import { AttachmentType } from "@/app/types/attachment";
import Image from "next/image";
import React from "react";

type Props = {
    title: string;
    subtitle: string;
    icon?: AttachmentType;
};

export default function TitleSubTitle({ title, subtitle, icon }: Props) {
    return (
        <div className='flex flex-row gap-x-4 title-subtitle-holder'>
            {!!icon && (
                <div className='icon-holder border-[.5px] border-black/[.08] w-[64px] h-[64px] flex flex-col items-center justify-center rounded-full'>
                    <Image src={process.env.NEXT_PUBLIC_API_URL + '/media/' + icon.id} width={40} height={40} alt={title ?? ""} />
                </div>
            )}
            <div className='flex flex-col gap-y-2'>
                <Typography className='text-lg font-semibold text-black title'>
                    {title}
                </Typography>
                <Typography className='text-sm font-normal !text-black/[.38] subtitle'>
                    {subtitle}
                </Typography>
            </div>
        </div>
    );
}
