import { isJson } from "../components/shared/ElementRenderer/Types/Item";
import ToastMessage from "../components/shared/ToastMessage";

const errorHandler = (errors: any) => {
  if (typeof window === "undefined") return;

  if (!isJson(errors?.message)) return ToastMessage.warning(errors.message);

  let errorsArray: any[] = Object.values(JSON?.parse(errors?.message));

  if (errorsArray?.length === 0) return;

  for (let error of errorsArray) {
    ToastMessage.warning(error);
  }
};

export default errorHandler;
