import React from "react"

type Props = {
  src: string;
  oggSrc?: string;
  className?: string;
  autoPlay?: boolean;
};

export default function VideoPlayer({
  src,
  oggSrc,
  className,
  autoPlay = false,
}: Props) {
  if (!src) return null;

  return (
    <video
      width='100%'
      height='auto'
      className={className}
      controls
      autoPlay={autoPlay}
    >
      <source src={src} type='video/mp4' />
      <source src={oggSrc || src} type='video/ogg' />
      Your browser does not support the video tag.
    </video>
  );
}
