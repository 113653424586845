import Timeline, { TimelineItemType } from "..";
import { Divider } from "../../../ui";
import SwiperSlider from "../../SwiperSlide";
import TimelineItem from "../Line/Item";
import SwiperTimelineItem from "./Item";

type Props = {
  items: TimelineItemType[];
  onSelect: (item: TimelineItemType) => void;
  selected: TimelineItemType;
};
export default function SwiperTimeline({
  items = [],
  onSelect,
  selected,
}: Props) {
  return (
    <div dir='ltr'>
      <SwiperSlider
        items={items?.map((x, key) => (
          <SwiperTimelineItem item={x} key={key} />
        ))}
        config={{
          slidesPerView: 1.2,
          spaceBetween: 32,
        }}
        hasNavigation={false}
      />
    </div>
  );
}
