import renderHTML from "react-render-html";
import React from "react"

type Props = {
  html: string;
  className?: string;
};
export default function RenderHtml({ html, className }: Props) {
  if (!html) return;
  return (
    <div
      className={`html-renderer [&_>h1]:text-2xl [&_>h2]:text-xl [&_>h3]:text-lg [&_>h4]:text-md [&_>h5]:text-sm [&_>h6]:text-xs [&_>h1]:my-4 [&_>h2]:my-4  [&_>h3]:my-4 [&_>h4]:my-4 [&_>h5]:my-4 [&_>h6]:my-4 ${
        className ?? ""
      }`}
    >
      {renderHTML(html)}
    </div>
  );
}
