import { ElementType } from "@/app/types/element";
import TypeItem, { RightValue } from "../Item";
import TitleEl from "../../../TitleEl";
import React from "react";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function TitleElElement({ item, value }: Props) {
  let content = (
    <TitleEl
      title={value?.title ?? "-"}
      titleClassName={`text-lg font-semibold ${value?.title_class ?? ""}`}
      className={`!gap-y-6 ${value?.class ?? ""}`}
    >
      <TypeItem
        item={{
          item: value?.component?.component,
          value: value?.component?.value,
        }}
      />
    </TitleEl>
  );

  return value?.width ? (
    <div
      className="flex flex-col mx-auto items-center py-4 max-w-full"
      style={{ width: +value?.width || "100%" }}
    >
      {content}
    </div>
  ) : (
    content
  );
}
