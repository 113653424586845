import { Typography } from "@/app/components/ui";
import { AttachmentType } from "@/app/types/attachment";
import { getOptimizedMediaUrl } from "@/app/utils";
import React from "react";
import VahdatImage from "../../../VahdatImage";
import { RightValue } from "../Item";

type Props = {
  value?: RightValue;
};

export default function IconTitleDescElement({ value }: Props) {
  const title = value?.title;
  const desc = value?.desc;
  const icon: AttachmentType | undefined = value?.icon;

  return (
    <div
      className={`relative max-w-full flex flex-col h-full justify-center p-6 md:p-8 title-desc-element ${
        value?.class ?? ""
      }`}
    >
      <div className='col-holder flex flex-col justify-start items-center gap-y-2'>
        {!!icon && (
          <VahdatImage
            alt=''
            width={60}
            height={60}
            src={getOptimizedMediaUrl({ mediaId: icon?.id })}
          />
        )}
        <Typography variant='h3' className='text-base title text-center'>
          {title}
        </Typography>
        <Typography
          className='!text-black/60 text-center text-xs desc leading-5 min-h-[60px]'
          variant='p'
        >
          {desc}
        </Typography>
      </div>
    </div>
  );
}
