import React, { ButtonHTMLAttributes, ReactNode } from "react"

export type ButtonProps = {
  fullWidth?: boolean
  endIcon?: ReactNode
  startIcon?: ReactNode
  loading?: boolean
  className?: string
  variant?: "contained" | "outlined" | "text"
}

type Props = ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps

export default function Button(props: Props) {
  const {
    variant = "contained",
    startIcon,
    endIcon,
    loading,
    disabled,
    fullWidth,
    className,
    type = "button",
    ...rest
  } = props

  let defaultClass = "w-auto"

  switch (variant) {
    case "outlined":
      defaultClass += ` border hover:bg-black/[.02] active:border-black/[.4]`
      if (!!!props?.className?.includes("border-")) {
        defaultClass += ` border-black/[.1]`
      }
      break
    case "text":
      defaultClass += ` text-primary active:border-primary hover:bg-primary/[.05] active:bg-primary/[.1] !border-0`
      break
    default:
    case "contained":
      defaultClass += ` bg-primary text-white active:shadow-md !border-0 hover:bg-darkPrimary`
      break
  }

  if (fullWidth) defaultClass += " w-full"

  if (className) defaultClass += ` ${className}`

  let content = (
    <>
      {!!startIcon && startIcon}
      {props.children}
      {!!endIcon && endIcon}
    </>
  )

  if (loading)
    content = (
      <svg className="circular-loader w-[24px] h-[24px]" viewBox="25 25 50 50">
        <circle
          className="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="rgba(0,0,0,0.2)"
          strokeWidth="4"
        />
      </svg>
    )

  return (
    <button
      {...rest}
      disabled={!!loading || disabled}
      className={`relative flex flex-row gap-x-2 items-center justify-center min-w-[80px] h-[48px] px-4  rounded-md hover:bg-sapp-dark-primary active:border-2  disabled:bg-[#F5F5F5] disabled:text-black/[.2] disabled:border-0 transition-all ${defaultClass}`}
      type={type}
    >
      {content}
    </button>
  )
}
