import React from "react"

type Props = {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
};

export default function CircularLoading({
  width = 24,
  height = 24,
  color = "rgba(0,0,0,0.2)",
  strokeWidth = 4,
}: Props) {
  return (
    <svg
    style={{
        width: `${width}px`,
        height: `${height}px`
    }}
      className={`circular-loader !relative !top-0 !left-0 !right-0 !translate-x-0 !translate-y-0`}
      viewBox="25 25 50 50"
    >
      <circle
        className="loader-path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
