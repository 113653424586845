import moment from "moment-jalali";
import "moment/locale/fa"; // Set the locale to Persian (Farsi)

export default function convertGregorianToShamsiDate(
  gregorianDate: string,
  shamsiFormat: string
): string {
  // Parse the input Gregorian date
  const gregorianMoment = moment(gregorianDate, "YYYY-MM-DD"); // Adjust the format as needed

  const shamsiDate = gregorianMoment.format(shamsiFormat);

  return shamsiDate;
}
