import React, { ReactNode } from "react";

type Props = {
  children: ReactNode
  title: string;
};
export default function Tooltip({ children, title }: Props) {
  return (
    <div className='relative [&_.text]:hover:opacity-100 [&_.text]:hover:visible'>
      <div className='text absolute whitespace-nowrap top-[-48px] py-1 px-2 left-[50%] translate-x-[-50%] bg-black/60 backdrop-blur-sm rounded-md text-white opacity-0 invisible transition-all'>
        {title}
      </div>
      {children}
    </div>
  );
}
