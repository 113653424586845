import React, { useCallback, useState } from "react"
import { Pagination } from "../components/ui"

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const pageChangeHandler = useCallback((val: number) => {
    setCurrentPage(val)
  }, [])

  const pagination = (total: number, perPage: number = 15) => {
    if (total === undefined) return

    return (
      <>
        <Pagination
          total={total}
          perPage={perPage}
          onChange={pageChangeHandler}
        />
      </>
    )
  }

  return {
    currentPage,
    renderPagination: pagination,
  }
}
export default usePagination
