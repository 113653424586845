import { TimelineItemType } from "..";
import { Typography } from "../../../ui";

type Props = {
  item: TimelineItemType;
  isSelected?: boolean;
  onSelect: (item: TimelineItemType) => void;
};

export default function TimelineItem({
  item,
  isSelected = false,
  onSelect,
}: Props) {
  let bulletClass = "bullet w-[7px] h-[7px] cursor-pointer rounded-full";
  let yearClass = "year absolute text-base !text-black/20 bottom-0";
  if (isSelected) {
    bulletClass += ` bg-darkPrimary`;
  } else {
    bulletClass += ` bg-[#f0f0f0]`;
  }

  return (
    <div
      className='relative flex flex-col gap-y-8 items-center px-16 py-16 cursor-pointer [&_.bullet]:hover:!bg-primary [&_.year]:hover:!text-primary'
      onClick={() => onSelect(item)}
    >
      {!!isSelected && (
        <Typography
          className={"text-darkPrimary absolute top-0 font-bold text-3xl"}
        >
          {item.year}
        </Typography>
      )}
      <div className={bulletClass}></div>
      {!!!isSelected && (
        <Typography className={yearClass}>{item.year}</Typography>
      )}
    </div>
  );
}
