import { AttachmentType } from "@/app/types/attachment";
import { ElementType } from "@/app/types/element";
import { getOptimizedMediaUrl } from "@/app/utils";
import React from "react";
import { RightValue } from "../Item";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function ImageElement({ item, value }: Props) {
  const media: AttachmentType | null = value?.media;
  //Image width
  const width = value?.width;
  //Image height
  const height = value?.width;

  //Image alt
  const alt = value?.alt ?? "";

  if (media === undefined) return null;

  return (
    <img
      width={width}
      height={height}
      src={getOptimizedMediaUrl({ mediaId: media?.id })}
      alt={alt}
    />
  );
}
