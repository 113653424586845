import { AttachmentType } from "@/app/types/attachment";
import SlideDetails from "./Details";
import { getOptimizedMediaUrl } from "@/app/utils";
import React from "react";

type Props = {
  title: string;
  description: string;
  btnText: string;
  btnLink: string;
  gradientStart: string;
  gradientEnd: string;
  media: AttachmentType;
  mobileMedia?: AttachmentType;
};
export default function Slide(props: Props) {
  const {
    title,
    description,
    btnLink,
    btnText,
    media,
    mobileMedia,
    gradientStart = "#000",
    gradientEnd = "transparent",
  } = props;

  const mediaSource = media?.id
    ? getOptimizedMediaUrl({ mediaId: media.id })
    : null;
  const mobileMediaSource = mobileMedia?.id
    ? getOptimizedMediaUrl({ mediaId: mobileMedia.id })
    : null;

  let grdientClss = "absolute top-0 bottom-0 right-0 left-0";

  if (!!!title && !!!description) grdientClss += " hidden";

  return (
    <div
      style={
        {
          "--mobile-image-url": `url(${mobileMediaSource ?? ""})`,
          "--image-url": `url(${mediaSource ?? ""})`,
        } as any
      }
      className={`h-[550px] bg-black/10 flex flex-row items-center px-16 md:!px-24 bg-cover bg-center bg-[image:var(--mobile-image-url)] md:bg-[image:var(--image-url)]`}
    >
      <SlideDetails
        btnLink={btnLink}
        btnText={btnText}
        description={description}
        title={title}
      />
      <div
        className={grdientClss}
        style={{
          background: `linear-gradient(to left, ${gradientStart}, ${gradientEnd})`,
        }}
      ></div>
    </div>
  );
}
