import { urlWithQueryParams } from "@/app/utils";
import React from "react"

type Props = {
  mediaId: string;
  width?: number | string;
  height?: number | string;
  action?: "thumbnail" | "cropThumbnail";
  background?: string; //Should be rgba
  quality?: number; //1 to 100
  opacity?: string; //0 to 1 - for instance 0.2
  alt?: string;
};
export default function ImageRenderer({ mediaId, alt, ...rest }: Props) {
  let url = urlWithQueryParams(
    `${process.env.NEXT_PUBLIC_API_URL}/media/${mediaId}`,
    {
      ...rest,
    }
  );

  return (
    <img
      width={rest?.width || "auto"}
      height={rest?.height || "auto"}
      src={url}
      alt={alt}
    />
  );
}
