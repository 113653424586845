import { TimelineItemType } from ".."
import { Divider } from "../../../ui"
import TimelineItem from "./Item"

type Props = {
  items: TimelineItemType[]
  onSelect: (item: TimelineItemType) => void
  selected: TimelineItemType
}
export default function Line({ items = [], onSelect, selected }: Props) {
  return (
    <div className="relative py-8" dir="ltr">
      <Divider className="absolute top-[calc(50%-3.5px)]" />
      <div className="flex flex-row w-full justify-between items-end mt-[-4px]">
        {items.map((item, index) => (
          <TimelineItem
            item={item}
            isSelected={selected?.year === item?.year}
            key={index}
            onSelect={onSelect}
          />
        ))}
      </div>
    </div>
  )
}
