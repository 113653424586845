"use client";

import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType, SwiperOptions } from "swiper/types";
import SwiperNextSlide from "./NextSlide";
import SwiperPrevSlide from "./PrevSlide";

type SliderType = ReactNode;

type Props = {
  config?: SwiperOptions;
  items: SliderType[];
  className?: string;
  onSwiper?: (swiper: SwiperType) => void;
  onNext?: () => void;
  onPrev?: () => void;
  afterSliderNode?: ReactNode;
  defaultIndex?: number;
  hasNavigation?: boolean;
};

export default function SwiperSlider({
  items = [],
  config = {
    spaceBetween: 50,
    slidesPerView: 1,
  },
  className = "",
  afterSliderNode,
  onSwiper,
  onNext,
  onPrev,
  defaultIndex,
  hasNavigation = true,
}: Props) {
  const [swiper, setSwiper] = useState<SwiperType>(null);

  const onSwiperHandler = useCallback(
    (currentSwiper: SwiperType) => {
      if (onSwiper) onSwiper(currentSwiper);
      setCurrentIndex(currentSwiper.activeIndex);
      setSwiper(currentSwiper);
    },
    [onSwiper]
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (defaultIndex !== undefined) setCurrentIndex(defaultIndex);
  }, [defaultIndex]);

  const handleNextSlide = () => {
    if (swiper) {
      swiper?.slideNext();
      setCurrentIndex(swiper.activeIndex);
    }
  };
  const handlePrevSlide = () => {
    if (swiper) {
      swiper?.slidePrev();
      setCurrentIndex(swiper.activeIndex);
    }
  };

  const changeSwiperHandler = useCallback(
    (changedSwiper: SwiperType) => {
      if (onSwiper) onSwiper(changedSwiper);
      setCurrentIndex(changedSwiper.activeIndex);
      setSwiper(changedSwiper);
    },
    [onSwiper]
  );

  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === items.length - 1;

  if (items.length === 0) return null;

  return (
    <div className={`swiper-container ${className} w-full h-full`}>
      <Swiper
        {...config}
        onSlideChange={changeSwiperHandler}
        loop
        onSwiper={onSwiperHandler}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      >
        {!!hasNavigation && (
          <SwiperPrevSlide
            onClick={handlePrevSlide}
            // isDisabled={isPrevDisabled}
            className="prev-button !bg-white absolute z-[1000] top-[50%] trasnlate-y-[-50%] right-0"
          />
        )}
        {items.map((swiper, index) => (
          <SwiperSlide key={index}>{swiper}</SwiperSlide>
        ))}
        {!!hasNavigation && (
          <SwiperNextSlide
            // isDisabled={isNextDisabled}
            onClick={handleNextSlide}
            className="next-button !bg-white absolute z-[1000] top-[50%] trasnlate-y-[-50%] left-0"
          />
        )}
      </Swiper>
      {!!afterSliderNode && afterSliderNode}
    </div>
  );
}
