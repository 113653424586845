"use client";

import { deviceName } from "@/app/const/device";
import useResize from "@/app/hooks/useResize";

import React, { ReactNode } from "react";

const deviceWidth: any = {
  Mobile: 768,
  Tablet: 1024,
  Desk: 3000,
};

type Props = {
  devices?: string[];
  children: ReactNode
};

export default function ShowOnDevices({
  devices = [deviceName.Desk, deviceName.Tablet, deviceName.Mobile],
  children,
}: Props) {
  const width = useResize();

  const devicesToNotShow = [];

  if (width === 0) return;

  for (let key of Object.keys(deviceWidth)) {
    if (!devices.includes(key)) devicesToNotShow.push(key);
  }

  if (devicesToNotShow.includes(deviceName.Tablet)) {
    if (width <= 1024 && width >= 768) return;
  }

  if (devicesToNotShow.includes(deviceName.Desk)) {
    if (width > 1024) return;
  }

  if (devicesToNotShow.includes(deviceName.Mobile)) {
    if (width < 768) return;
  }

  return <>{children}</>;
}
