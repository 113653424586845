import { ElementType } from "@/app/types/element";
import RowElement from "./Row";
import ColElement from "./Col";
import ImageElement from "./Image";
import BoxImage from "./BoxImage";
import CategoriesWithProducts from "./CategoriesWithProducts";
import CollectionElement from "./Collection";
import ContainerElement from "./Container";
import TabElement from "./Tabs";
import SpaceElement from "./Space";
import SlideShowElement from "./SlideShow";
import MapElement from "./Map";
import TitleDescBtnElement from "./TitleDescBtn";
import EtmlElement from "./Html";
import LatestBlogElement from "./LatestBlog";
import IconTitleDescElement from "./IconTitleDesc";
import HeadingElement from "./Heading";
import ParagraphElement from "./Paragraph";
import ButtonElement from "./Button";
import ButtonGroupHorizElement from "./ButtonGroupHoriz";
import ReelsElement from "./Reels";
import TitleElElement from "./TitleEl";
import ListElement from "./List";
import FaceShapesElement from "./FaceShapes";
import BlogVerticalElement from "./BlogVertical";
import TitleSubTitleElement from "./TitleSubTitle";
import WikiVerticalElement from "./WikiVertical";
import GridImagesElement from "./GridImages";
import DividerElement from "./Divider";
import TimelineElement from "./Timeline";
import AttributeLoaderElement from "./AttributeLoader";
import SwiperProductsElement from "./SwiperProducts";
import React from "react";

type Props = {
  item: ElementType;
};

export type RightValue = { [key: string]: any };

export function isJson(input: string) {
  try {
    const jsonObject = JSON.parse(input);
    if (typeof jsonObject === "object" && jsonObject !== null) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

function makeValuSemantic(item: ElementType): RightValue {
  let options = item?.item?.options;
  let keyValues = {};
  try {
    let itemValue = item?.value;

    if (isJson(itemValue)) itemValue = JSON.parse(itemValue);

    const keys = Object.keys(options);

    for (let key of keys) {
      let targetVal = itemValue?.[key];

      if (isJson(targetVal)) targetVal = JSON.parse(targetVal);

      keyValues[key] = targetVal;
    }
  } catch (e) {}

  return keyValues;
}

export default async function TypeItem({ item }: Props) {
  if (!item?.item?.key) return null;

  const value = makeValuSemantic(item);

  switch (item?.item?.key) {
    case "attribute_loader":
      return <AttributeLoaderElement item={item} value={value} />;
    case "timeline":
      return <TimelineElement item={item} value={value} />;
    case "divider":
      return <DividerElement item={item} value={value} />;
    case "grid_images":
      return <GridImagesElement item={item} value={value} />;
    case "title_subtitle":
      return <TitleSubTitleElement item={item} value={value} />;
    case "wiki_vertical":
      return <WikiVerticalElement item={item} value={value} />;
    case "blog_vertical":
      return <BlogVerticalElement item={item} value={value} />;
    case "face_shapes":
      return <FaceShapesElement item={item} value={value} />;
    case "title_el":
      return <TitleElElement item={item} value={value} />;
    case "reels":
      return <ReelsElement item={item} value={value} />;
    case "heading":
      return <HeadingElement item={item} value={value} />;
    case "button":
      return <ButtonElement item={item} value={value} />;
    case "paragraph":
      return <ParagraphElement item={item} value={value} />;
    case "latest_blog":
      return <LatestBlogElement value={value} />;
    case "icon_title_desc":
      return <IconTitleDescElement value={value} />;
    case "html":
      return <EtmlElement item={item} value={value} />;
    case "title_desc_btn":
      return <TitleDescBtnElement item={item} value={value} />;
    case "map":
      return <MapElement item={item} value={value} />;
    case "slider":
      return <SlideShowElement item={item} value={value} />;
    case "tab":
      return <TabElement item={item} value={value} />;
    case "space":
      return <SpaceElement item={item} value={value} />;
    case "container":
      return <ContainerElement item={item} value={value} />;
    case "swiper_products":
      return <SwiperProductsElement item={item} value={value} />;
    case "collection":
      return <CollectionElement item={item} value={value} />;
    case "categories_with_products":
      return <CategoriesWithProducts item={item} value={value} />;
    case "image":
      return <ImageElement item={item} value={value} />;
    case "box_image":
      return <BoxImage item={item} value={value} />;
    case "list":
      return <ListElement value={value} />;
    case "row":
      return <RowElement value={value} />;
    case "columns":
      return <ColElement value={value} />;
    case "button-group-horiz":
      return <ButtonGroupHorizElement value={value} />;
  }
}
