import React, { ReactNode } from "react"
import { Typography } from "../ui"

type Props = {
  title?: ReactNode
  children: ReactNode
  className?: string
  titleClassName?: string
}
export default function TitleVal({
  title,
  children,
  className,
  titleClassName = "font-semibold text-xl",
}: Props) {
  return (
    <div className={`flex flex-col gap-y-4 ${className || ""}`}>
      {!!title && <strong className={titleClassName}>{title}</strong>}
      <span className="value">{children}</span>
    </div>
  )
}
