import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { IconButton } from ".";

type Props = {
  children: ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>;
export default function FabButton(props: Props) {
  return (
    <IconButton
      {...props}
      className={`!bg-primary w-[56px] h-[56px] ${props?.className || ""}`}
    >
      {props.children}
    </IconButton>
  );
}
