"use client"
import React from "react"

export type statusBoxVariants =
  | "success"
  | "info"
  | "error"
  | "warning"
  | "default"
interface Props {
  variant: statusBoxVariants
  label: string
  alpha?: string
}

const StatusBox = ({ variant = "info", label, alpha = "0.1" }: Props) => {
  let bgColor = "rgba(0,0,0,1)"

  switch (variant) {
    case "success":
      bgColor = `rgba(56,142,60,${alpha})`
      break
    case "info":
      bgColor = `rgba(2,136,209,${alpha})`
      break
    case "warning":
      bgColor = `rgb(245,158,11,${alpha})`
      break
    case "error":
      bgColor = `rgba(230,74,25,${alpha})`
      break
    default:
      bgColor = `rgba(0,0,0,${alpha})`
      break
  }

  return (
    <div
      className="inline-flex items-center justify-center w-auto p-2 px-3 text-sm font-normal rounded-[32px]"
      style={{
        backgroundColor: bgColor,
      }}
    >
      {label}
    </div>
  )
}

export default StatusBox
