import React, { ReactNode } from "react";

type Button = {
  icon?: ReactNode;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
};

type Props = {
  buttons: Button[];
  className?: string;
};

export default function ButtonGroup({ buttons, className }: Props) {
  if (buttons.length === 0) return null;

  return (
    <div className={`flex flex-row justify-between gap-x-2 ${className || ""}`}>
      {buttons.map((btn, index) => (
        <div
          className={`flex flex-col items-center gap-y-2 cursor-pointer py-4 w-[100px] hover:[&_*]:!text-black [&_path]:hover:!stroke-black ${
            btn?.disabled ? "pointer-events-none" : ""
          }`}
          key={index}
          onClick={btn?.onClick}
        >
          {!!btn.icon && btn.icon}
          <span className="font-light text-text-grey text-[13px]">
            {btn.label}
          </span>
        </div>
      ))}
    </div>
  );
}
