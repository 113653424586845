"use client";

import React, { InputHTMLAttributes, ReactNode } from "react";
import { SearchIcon } from "../icons";

interface Props {
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  beforeInputNode?: ReactNode;
}

const SearchBox = ({
  className,
  inputClassName,
  inputProps,
  placeholder = "جست و جو...",
  beforeInputNode,
}: Props) => {
  return (
    <div
      className={`flex flex-row items-center justify-between w-full bg-black/[0.04] rounded-lg pl-4 pr-2 py-1 gap-x-2 [&_input]:w-full [&_input]:py-2 [&_input]:bg-transparent [&_input]:outline-none ${className}`}
    >
      {!!beforeInputNode && <div>{beforeInputNode}</div>}
      <input
        autoFocus
        {...inputProps}
        placeholder={placeholder}
        className={`${inputClassName}`}
      />
      <SearchIcon />
    </div>
  );
};

export default SearchBox;
