"use client";

import React, { ImgHTMLAttributes, useEffect, useRef, useState } from "react";

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  fill?: boolean;
};

export default function VahdatImage({ fill, ...rest }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  function onLoad() {
    setIsLoaded(true);
  }

  useEffect(() => {
    if (imgRef.current?.complete) {
      onLoad();
    }
  }, [imgRef?.current]);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  let src = rest?.src;

  let clss = `object-cover object-center ${rest?.className ?? ""}`;

  clss += ` transition-all`;

  if (!isVisible && !isLoaded) clss += ` opacity-0`;

  if (isVisible || isLoaded) clss += ` opacity-100`;

  if (fill) clss += ` absolute h-full w-full`;

  let content = (
    <img
      {...rest}
      ref={imgRef}
      className={clss}
      src={src}
      onLoad={onLoad}
      decoding='async'
    />
  );

  return fill ? (
    <div className='relative w-full h-full'>{content}</div>
  ) : (
    content
  );
}
