export default function splitArrayBySpecificKey(
  arr: any[],
  specificKey: string
): any[] {
  return arr.reduce((groups: { [key: string]: any[] }, item: any) => {
    const keyValue = item[specificKey];

    if (!groups[keyValue]) {
      groups[keyValue] = [];
    }

    groups[keyValue].push(item);
    return groups;
  }, {});
}
