import React, {
  InputHTMLAttributes,
  ReactNode,
  TextareaHTMLAttributes,
  forwardRef,
} from "react"

export type TextInputType = {
  afterNode?: ReactNode
  hasNumberFormat?: boolean
  inputProps?:
    | InputHTMLAttributes<HTMLInputElement>
    | TextareaHTMLAttributes<HTMLTextAreaElement>
  label?: string
  className?: string
  error?: boolean
  multiline?: boolean
  helperText?: string
  variant?: "outlined" | "standard"
}

const TextInput = forwardRef<HTMLInputElement, TextInputType>(
  (props: TextInputType, ref) => {
    const {
      afterNode,
      inputProps,
      hasNumberFormat = false,
      label,
      error = false,
      helperText,
      className = "",
      multiline = false,
      variant = "standard",
    } = props

    let inputValue = props?.inputProps?.value
    if (hasNumberFormat) inputValue = inputValue?.toLocaleString()

    let inputClass = `block outline-none w-full rounded-md h-[56px] pr-4 text-black  placeholder:text-black/[.6] focus:border-primary sm:leading-6 text-base ${inputProps?.className}`

    switch (variant) {
      case "outlined":
        inputClass += ` border border-black/[.2]`
        break
      default:
      case "standard":
        inputClass += ` bg-black/[.04] focus:bg-black/[.06] [&.error-state]:!bg-primary/[.1]`
        break
    }

    if (props?.inputProps?.className)
      inputClass += ` ${props?.inputProps?.className}`

    let helperTextClass = "text-[14px] text-black/[.6]"
    if (error) {
      //Change helper text class
      helperTextClass = "text-[14px] text-red-500"

      //Change input class
      inputClass += ` error-state !border-red-500`
    }

    if (multiline) inputClass += ` py-4 min-h-[200px]`

    const finalInputProp: any = {
      type: "text",
      ...inputProps,
      className: inputClass,
      ...(inputValue
        ? {
            value: inputValue,
          }
        : {}),
    }

    return (
      <div className={`w-full flex flex-col items-start gap-y-2 ${className}`}>
        {label && <span className="text-[14px]">{label}</span>}
        <div className="w-full relative rounded-lg">
          {!!afterNode && (
            <div className="after-node pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4 ">
              {afterNode}
            </div>
          )}
          {multiline ? (
            <textarea {...finalInputProp}>{inputValue}</textarea>
          ) : (
            <input ref={ref} {...finalInputProp} />
          )}
          {helperText && (
            <span className={`helper-text flex mt-2 ${helperTextClass}`}>
              {helperText}
            </span>
          )}
        </div>
      </div>
    )
  }
)

export default TextInput
