"use client"

import { Paper } from "../ui"
import React, { ReactNode, useEffect } from "react"

type Props = {
  children: ReactNode
  className?: string
  hasShadow?: boolean
  hasBorder?: boolean
  isGravity?: boolean
}

export default function PaperMobileFull({
  children,
  className,
  isGravity = false,
  hasShadow,
  hasBorder,
}: Props) {
  let defautClass =
    "fixed paper-mobile-full z-10 left-0 top-0 md:left-auto w-full pb-24 md:pb-8 md:top-0 md:relative md:w-[440px] flex flex-col gap-y-8 rounded-none md:rounded-xl overflow-auto h-full max-w-[100%]"

  if (!!isGravity) {
    defautClass +=
      " top-auto bottom-0 md:bottom-auto h-fit !rounded-tr-2xl !rounded-tl-2xl "
  }

  useEffect(() => {
    const root = document.getElementById("root")

    function handleAdd() {
      root?.classList?.add("overflow-hidden")
    }

    function handleRemove() {
      root?.classList?.remove("overflow-hidden")
    }

    handleAdd()

    return () => {
      handleRemove()
    }
  }, [])

  return (
    <Paper
      hasShadow={hasShadow}
      hasBorder={hasBorder}
      className={`${defautClass} ${className}`}
    >
      {children}
    </Paper>
  )
}
