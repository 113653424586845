import { ElementType } from "@/app/types/element"

import TypeItem, { RightValue } from "../Item"

import TitleEl from "../../../TitleEl"
import Link from "next/link"
import Image from "next/image"
import { Typography } from "@/app/components/ui"
import React from "react";

type Props = {
  item: ElementType
  value?: RightValue
}

export default function FaceShapesElement({ item, value }: Props) {
  return (
    <div className="grid grid-cols-12 md:grid-cols-10 gap-3">
      {[
        {
          title: "مستطیل",
          href: "#",
          icon: "/assets/shape-1.png",
        },
        {
          title: "مربعی",
          href: "#",
          icon: "/assets/shape-2.png",
        },
        {
          title: "گرد",
          href: "#",
          icon: "/assets/shape-3.png",
        },
      ].map((item, key) => (
        <Link
          prefetch={false}
          href={item.href}
          key={key}
          className="col-span-4 md:col-span-2 py-4 flex flex-col items-center justify-center rounded-md border border-black/10 hover:border-black/20 transition-all"
        >
          <Image
            width={80}
            height={40}
            src={item.icon}
            alt={item.title ?? ""}
          />
          <Typography>{item.title}</Typography>
        </Link>
      ))}
    </div>
  )
}
