"use client"

import axiosInstance from "@/api/axios"
import { ElementType } from "@/app/types/element"
import { urlWithQueryParams } from "@/app/utils"
import { BlogType } from "@/types/blog"
import moment from "jalali-moment"
import React from "react"
import useSWR from "swr"
import { EntityVertical } from "../../.."
import { RightValue } from "../Item"

type Props = {
    item: ElementType
    value?: RightValue
}

export default function BlogVerticalElement({ item, value }: Props) {
    const { data, isLoading } = useSWR("/latest-blog-4", async () => {
        const res = await axiosInstance.get(
            urlWithQueryParams(`/blog`, {
                per_page: 4,
            })
        )
        return res?.data?.data ?? []
    })



    const blogItems: BlogType[] = !!data ? data : []

    if (isLoading || data === undefined) return null
    return (
        <EntityVertical
            moreLink={process.env.NEXT_PUBLIC_BLOG_URL ?? "#"}
            items={blogItems?.map((x) => ({
                title: x.title,
                thumbnail: x.post_thumbnail,
                href: x.link,
                subtitle: moment(x.date).locale("fa").fromNow(),
            }))}
        />
    )
}
