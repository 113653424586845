import React, { ReactNode } from "react";

import Link from "next/link";
import { Typography } from "../ui";

export type ListMenuItem = {
  icon?: ReactNode;
  title: string;
  href?: string;
};

type Props = {
  title: string;
  menus: ListMenuItem[];
  title_class?: string;
};
export default function SharedList({
  title,
  menus,
  title_class = "font-semibold text-lg",
}: Props) {
  return (
    <div className="flex flex-col gap-y-4">
      <Typography className={title_class}>{title}</Typography>
      <div className="flex flex-col gap-y-2">
        {menus.map((item, index) => {
          let sharedClass =
            "flex flex-row gap-x-4 items-start w-[260px] max-w-full [&_.title]:hover:!text-primary";

          let content = (
            <>
              {!!item?.icon && (
                <div className="flex flex-row items-center mt-2">
                  {item.icon}
                </div>
              )}
              <Typography className="title text-[16px] font-light !text-black/[.72] hover:text-black">
                {item.title}
              </Typography>
            </>
          );

          return item.href ? (
            <Link
              key={index}
              href={item.href}
              prefetch={false}
              title={item.title}
              className={sharedClass}
            >
              {content}
            </Link>
          ) : (
            <div key={index} className={sharedClass}>
              {content}
            </div>
          );
        })}
      </div>
    </div>
  );
}
