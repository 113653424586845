import { IconButton, Typography } from "@/app/components/ui"
import React, { ReactNode, useCallback } from "react"
import BackIcon from "../icons/Back"

type Props = {
  title: string
  children: ReactNode
  icon?: ReactNode
  onIconClick?: () => void
  className?: string
}

export default function PageWrapper({
  title,
  children,
  icon = <BackIcon />,
  onIconClick,
  className,
}: Props) {
  const onIconClickHandler = useCallback(() => {
    if (onIconClick) onIconClick()
  }, [])

  return (
    <div className={`flex flex-col w-full gap-y-8 ${className || ""}`}>
      <div className="page-heading flex flex-row items-center gap-x-2">
        {!!onIconClick && (
          <IconButton onClick={onIconClickHandler}>{icon}</IconButton>
        )}
        <Typography className="page-title text-xl font-medium">
          {title}
        </Typography>
      </div>
      <div className="main-layout pl-4">{children}</div>
    </div>
  )
}
