import axiosInstance from "@/api/axios";
import { Typography } from "@/app/components/ui";
import { urlWithQueryParams } from "@/app/utils";
import React from "react";
import Product from "./Product";

type Props = {
  title: string;
  category: any;
  limit?: number;
};

const getProductsByCategory = async (category_id: string, limit: number) => {
  const res = await axiosInstance.get(
    urlWithQueryParams(`/product/site`, {
      product_class_id: category_id,
      limit,
    })
  );
  return res?.data;
};

export default async function CategoryBox({
  title,
  category,
  limit = 6,
}: Props) {
  let products = [];

  try {
    const items = await getProductsByCategory(category?.id, limit);
    products = items?.data ?? [];
  } catch (e) {}

  let productsNode = (
    <Typography className="text-center !text-black/60 text-xs">
      محصولی یافت نشد!
    </Typography>
  );

  if (products.length > 0) {
    productsNode = (
      <>
        <div className="grid grid-rows-3 md:grid-rows-2 grid-flow-col gap-2">
          {products.map((product, index) => (
            <Product product={product} key={index} />
          ))}
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col gap-y-6">
      <Typography className="text-xl font-normal text-center" variant="h3">
        {title}
      </Typography>
      {productsNode}
    </div>
  );
}
