import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function BackIcon({
  color = defaultIconColor,
  size = 24,
}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9304_3358)">
        <path
          d="M5.00002 12.9998L16.17 12.9998L11.29 17.8798C10.9 18.2698 10.9 18.9098 11.29 19.2998C11.68 19.6898 12.31 19.6898 12.7 19.2998L19.29 12.7098C19.68 12.3198 19.68 11.6898 19.29 11.2998L12.7 4.70978C12.31 4.31978 11.68 4.31978 11.29 4.70978C10.9 5.09978 10.9 5.72978 11.29 6.11978L16.17 10.9998L5.00002 10.9998C4.45002 10.9998 4.00002 11.4498 4.00002 11.9998C4.00002 12.5498 4.45002 12.9998 5.00002 12.9998Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9304_3358">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(24 24) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
