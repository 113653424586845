"use client";

import { Colors } from "@/app/const/colors";
import copy from "copy-text-to-clipboard";
import React, { useState } from "react";
import { CheckIcon, CopyIcon } from "../icons";
import { Button } from "../ui";

type Props = {
  label: string;
  value?: string;
  onCopied?: () => void;
  className?: string;
};
export default function Copy({ label, value, onCopied, className }: Props) {
  const finalVal = value || label;

  const [loading, setLoading] = useState(false);
  function copieClickHandler() {
    if (!finalVal) return;

    setLoading(true);
    copy(finalVal);
    if (onCopied) onCopied();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  return (
    <Button
      variant="text"
      onClick={copieClickHandler}
      startIcon={
        loading ? (
          <CheckIcon color={Colors.primary} />
        ) : (
          <CopyIcon color={Colors.primary} />
        )
      }
      className={`${loading ? "!bg-primary/[.05] !text-primary" : ""} ${
        className || ""
      }`}
    >
      {label}
    </Button>
  );
}
