import React from "react";
import TypeItem, { RightValue } from "../Item";

type Props = {
  value: RightValue;
};

export default function RowElement({ value }: Props) {
  //Classes
  const boxClass = value?.class;
  const mobileClass = value?.mobile_class ?? `col-span-12`;
  const itemClass = value?.item_class;

  return (
    <>
      <div
        style={
          {
            "--mobile-class": `${mobileClass}`,
          } as any
        }
        className={`grid grid-cols-12 gap-2 ${boxClass}`}
      >
        {value?.rows?.map((item, index) => (
          <div
            key={index}
            className={
              itemClass
                ? itemClass
                : `${mobileClass} lg-col-span-${item?.colSpan}`
            }
          >
            <TypeItem item={{ item: item?.component, value: item?.value }} />
          </div>
        ))}
      </div>
    </>
  );
}
