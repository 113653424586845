"use client";

import { ElementType } from "@/app/types/element";
import { isJson, RightValue } from "../Item";

import SwiperSlider from "../../../SwiperSlide";
import Slide from "./Slide";
import React from "react";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function SlideShowElement({ item, value }: Props) {
  let sliderItems = value?.items ?? [];

  sliderItems = value?.items?.map((item) => {
    let media = item?.media;
    let mobileMedia = item?.mobile_media;

    try {
      if (isJson(item?.media)) media = JSON.parse(item?.media);
      if (isJson(item?.mobile_media))
        mobileMedia = JSON.parse(item?.mobile_media);
    } catch (e) {}

    if (media === undefined) return null;

    return (
      <Slide
        title={item?.title ?? ""}
        description={item?.description ?? ""}
        btnText={item?.btn_text ?? ""}
        btnLink={item?.btn_link ?? "#"}
        media={media}
        mobileMedia={mobileMedia}
        gradientStart={item?.gradient_color_start}
        gradientEnd={item?.gradient_color_end}
      />
    );
  });

  return (
    <SwiperSlider
      className='[&_.prev-button]:top-[initial] [&_.prev-button]:md:top-[50%] [&_.prev-button]:md:right-4 [&_.next-button]:top-[initial] [&_.next-button]:left-[37%] [&_.prev-button]:right-[37%] [&_.next-button]:bottom-6 [&_.prev-button]:bottom-6 [&_.next-button]:md:top-[50%] [&_.next-button]:md:left-4'
      items={sliderItems?.filter((item) => item !== null)}
      config={{
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
      }}
    />
  );
}
