"use client"

import { toast } from "sonner";

const shouldLoginToast = (router: any, callback: string = null) => {
    toast.error("برای این کار نیاز به ورود به ناحیه کاربری دارید.", {
        action: {
            label: "ورود",
            onClick: () => {
                let url = '/fa/sign-in'
                if (callback) url += "?callback_url=" + callback;
                router.push(url)
            },
        },
        classNames: {
            actionButton: "!bg-primary",
        },
    });
};

export default shouldLoginToast;
