"use client";

import React, { ChangeEvent, useState, forwardRef, useEffect } from "react";
import { Typography } from ".";

type placementType = "Right" | "Left" | "Top" | "Bottom";
interface Props {
  inputId?: string;
  defaultValue?: string;
  label?: string;
  value: string;
  checked?: boolean;
  placement?: placementType;
  className?: string;
  onSelect?: (val: string) => void;
}

const Radio = forwardRef<HTMLInputElement, Props>(
  (
    {
      inputId,
      defaultValue,
      onSelect,
      value,
      checked = false,
      label,
      className,
      placement = "Right",
    }: Props,
    ref
  ) => {
    const [selected, setSelected] = useState(checked);
    useEffect(() => {
      setSelected(checked);
    }, [checked]);

    const targetId =
      inputId || `rnd-input-${Math.round(Math.random() * 100000)}`;

    let rootClss = `cursor-pointer flex items-center flex-row-reverse gap-y-2 gap-x-4 w-full justify-start `;
    if (!!label) {
      switch (placement) {
        case "Left":
          rootClss += " !flex-row ";
          break;
        case "Top":
          rootClss += " !flex-col-reverse ";
          break;
        case "Bottom":
          rootClss += " !flex-col ";
          break;
        default:
          rootClss += " !flex-row-reverse ";
          break;
      }
    }
    let radioClss =
      "w-5 h-5 flex items-center justify-center border-2 rounded-full border-[#5C5C5C] ";

    if (selected) radioClss += " !border-primary";

    let currLabel = null;
    if (!!label)
      currLabel = (
        <Typography className="label text-base font-medium" variant="span">
          {label}
        </Typography>
      );

    let innerBullet = (
      <div className="w-[10px] h-[10px] bg-primary rounded-full" />
    );

    const changeRadioHandler = (e: ChangeEvent<HTMLInputElement>) => {
      let targetVal = e.target.value;
      setSelected(e.target.checked);
      if (onSelect) onSelect(targetVal);
    };

    return (
      <label htmlFor={targetId} className={`${rootClss}${className}`}>
        <div className={radioClss}>
          <input
            type="radio"
            checked={selected}
            value={value}
            {...(defaultValue && { defaultValue })}
            id={targetId}
            onChange={changeRadioHandler}
            hidden
            ref={ref}
          />
          {selected && <>{innerBullet}</>}
        </div>
        {currLabel}
      </label>
    );
  }
);

export default Radio;
