import React, { HtmlHTMLAttributes, ReactNode } from "react"

type Props = {
  children: ReactNode
} & HtmlHTMLAttributes<any>
export default function Card(props: Props) {
  const { children } = props
  return (
    <div
      {...props}
      className={`p-3 gap-y-6 justify-between cursor-pointer shadow-sm hover:shadow-md transition-all border border-black/[.08] rounded-xl ${
        props?.className || ""
      }`}
    >
      {children}
    </div>
  )
}
