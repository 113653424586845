"use client"

import { Fragment } from "react";

type Props = {
    className?: string;
    style?: object;
};
export default function Skeleton({ className, style = {} }: Props) {
    return (
        <Fragment>
            <div
                className={`skeleton animate-pulse bg-black/[0.07] max-w-full ${className || ""}`}
                style={style}
            />
        </Fragment>
    );
}
