"use client";

import useResize from "./useResize";

const useDevice = ():
  | "mobile"
  | "tablet"
  | "desk"
  | "xLarge"
  | "2xLarge"
  | "midDesk" => {
  const width = useResize();

  if (width < 768) return "mobile";
  if (width < 1024) return "tablet";
  if (width < 1280) return "desk";
  if (width >= 1280 && width <= 1420) return "midDesk";
  if (width <= 1536) return "xLarge";
  if (width > 1536) return "2xLarge";

  return "desk";
};

export default useDevice;
