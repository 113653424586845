import { ElementType } from "@/app/types/element";
import { RightValue } from "../Item";
import React from "react";

type Props = {
    item: ElementType;
    value?: RightValue;
};

export default function MapElement({ item, value }: Props) {
    const lat = value?.lat;
    const long = value?.long;
    const className = value?.class;

    if (!lat || !long) return null;

    return (
        <div
            className={`relative overflow-hidden top-[32px] w-full after:absolute after:bottom-0 after:left-0 after:right-0  after:h-full after:bg-gradient-to-t after:from-white after:to-transparent after:z-[1] ${className ?? "h-auto"
                }`}
        >
            <iframe
                src={`https://maps.google.com/maps?q=${lat},${long}&hl=es;z=14&output=embed`}
                loading='lazy'
                width={"100%"}
                height={400}
                referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
        </div>
    );
}
