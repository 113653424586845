export default function convertGregorianDayToShamsi(day) {
  switch (day) {
    case "Fri":
      return "جمعه";
    case "Sat":
      return "شنبه";
    case "Sun":
      return "یکشنبه";
    case "Mon":
      return "دوشنبه";
    case "Tue":
      return "سه‌شنبه";
    case "Wed":
      return "چهارشنبه";
    case "Thu":
      return "پنجشنبه";
  }
}
