import { ElementType } from "@/app/types/element";
import { RightValue } from "../Item";
import { Typography } from "@/app/components/ui";
import React from "react";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function HeadingElement({ value }: Props) {
  const headingText = value?.text ?? null;
  const headingTextSize = value?.text_size ?? null;

  const variant = value?.variant ?? "p";
  let className = "leading-10";
  if (value?.class) className += ` ${value?.class ?? ""}`;

  if (headingText === null) return null;

  const objectStyle: any = {};
  if (headingTextSize) objectStyle.fontSize = `${headingTextSize}px`;

  return (
    <Typography variant={variant} className={className} style={objectStyle}>
      {headingText}
    </Typography>
  );
}
