"use client";
import { createPortal } from "react-dom";
import React, { MouseEvent, ReactNode, useEffect, useState } from "react";

type Props = {
  children: ReactNode
  close: () => void;
  isOpen?: boolean;
  className?: string;
};

export default function Backdrop({
  children,
  isOpen,
  close,
  className,
}: Props) {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const closeBackdropHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    close();
  };

  if (!children) return null;

  if (!open) return null;

  return (
    <>
      {createPortal(
        (
          <>
            <div
              className={`fixed top-0 left-0 z-[10000] w-full h-screen ${
                className || ""
              }`}
            >
              <div
                onClick={closeBackdropHandler}
                className='absolute top-0 left-0 w-full h-full bg-black/[.7] transition-all z-[1]'
              ></div>
              <div className='relative modal-inner-holder z-[2] max-h-[calc(100%-32px)] md:max-w-full'>
                {children}
              </div>
            </div>
          </>
        ) as any,
        document.getElementById("portal") as any
      )}
    </>
  );
}
