"use client";
import React, { ReactNode } from "react";
import { DropDownItemType } from ".";

type Props = {
  item: DropDownItemType;
  onClick?: (item: DropDownItemType) => void;
};

export default function Item({ item, onClick }: Props) {
  const clickItemHandler = () => {
    if (onClick) onClick(item);
  };

  return (
    <div
      onClick={clickItemHandler}
      className="flex flex-row justify-between p-2 bg-white hover:bg-primary/[.12] active:bg-primary/[.24] rounded-lg cursor-pointer"
    >
      <span className="title text-black">{item.title}</span>
      {!!item.label && (
        <div className="flex items-center gap-x-2">
          <span className="value text-black/[.38]">{item.label}</span>
        </div>
      )}
    </div>
  );
}
