import { getOptimizedMediaUrl } from "@/app/utils";
import { TimelineItemType } from ".";
import { Typography } from "../../ui";
import RenderHtml from "../RenderHtml";
import React from "react"

type Props = {
  item: TimelineItemType;
};
export default function SelectedPreview({ item }: Props) {
  return (
    <div className='flex flex-row justify-between'>
      <div className='flex flex-col gap-y-6 justify-end'>
        <Typography className='text-3xl text-[#451C0B] font-bold'>
          {item.title}
        </Typography>
        <RenderHtml html={item?.desc ?? ""} />
      </div>
      <img
        src={getOptimizedMediaUrl({
          mediaId: item?.image?.id,
        })}
        alt={item?.title ?? ""}
        className='w-[400px] h-auto max-w-full rounded-md'
      />
    </div>
  );
}
