import { ElementType } from "@/app/types/element"
import { GridImages, TitleEl } from "../../.."
import { isJson, RightValue } from "../Item"
import Link from "next/link"
import { Button } from "@/app/components/ui"
import ChevronLeft from "@/app/components/icons/ChevronLeft"
import { Colors } from "@/app/const/colors"
import { AttachmentType } from "@/app/types/attachment"
import React from "react";

type Props = {
    item: ElementType
    value?: RightValue
}

export default function GridImagesElement({ item, value }: Props) {
    const items = value?.items ?? []

    const title = value?.title

    const moreLink = value?.more_link
    const moreText = value?.more_text ?? "مشاهده همه"
    const className = value?.class_name ?? undefined

    return (
        <TitleEl
            title={title}
            titleClassName={`flex title !text-lg !font-semibold`}
            className={`${className ?? ""}`}
        >
            <GridImages
                items={items?.map((item) => {
                    let image: AttachmentType = item?.image
                    try {
                        if (isJson(item?.image)) image = JSON.parse(item?.image)
                    } catch (e) { }

                    return {
                        title: item?.title ?? "",
                        href: item?.link,
                        image,
                    }
                })}
                col_span={+(value?.col_span ?? "0")}
                col_span_lg={value?.col_span_lg ? +value?.col_span_lg : undefined}
                col_span_md={value?.col_span_md ? +value?.col_span_md : undefined}
            />
            {!!moreLink && (
                <Link href={moreLink} prefetch={false}>
                    <Button
                        className="!bg-transparent"
                        variant="text"
                        endIcon={<ChevronLeft color={Colors.primary} />}
                    >
                        {moreText}
                    </Button>
                </Link>
            )}
        </TitleEl>
    )
}
