import VahdatImage from "@/app/components/shared/VahdatImage"
import { ProductSiteType } from "@/app/types/product-site"
import { getOptimizedMediaUrl } from "@/app/utils"
import Link from "next/link"
import React from "react"

type Props = {
    product: ProductSiteType
}
export default function Product({ product }: Props) {
    const productMedia = product?.details?.[0]?.images?.[0]

    let productImage = productMedia
        ? getOptimizedMediaUrl({
            mediaId: productMedia?.id,
            width: 200,
            height: 200,
        })
        : ""
    if (!productImage && productMedia?.id)
        productImage = getOptimizedMediaUrl({
            mediaId: productMedia.id,
            width: 200,
            height: 200,
        })

    const hasImage = !!productMedia

    return (
        <>
            <Link
                href={`/fa/products/${product.slug}`}
                prefetch={false}
                className="flex flex-col items-center justify-center"
            >
                <VahdatImage width={200} height={200} alt="" src={productImage} />
            </Link>
        </>
    )
}
