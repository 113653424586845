import { Typography } from "@/app/components/ui"
import Link from "next/link"
import React, { ReactNode } from "react"

export type PostProps = {
  title: string
  thumbnail?: string
  href: string
  subtitle?: ReactNode
  hasTumbnail?: boolean
}

export default function Post({
  title,
  thumbnail,
  href,
  subtitle,
  hasTumbnail = true,
}: PostProps) {
  let detailClass = "flex flex-col gap-y-1"
  if (thumbnail) {
    detailClass += ` w-[calc(100%-104px)]`
  } else {
    detailClass += ` w-full`
  }

  let styleBg = {}
  if (thumbnail) styleBg["backgroundImage"] = `url(${thumbnail})`

  return (
    <Link
      href={href}
      prefetch={false}
      className="[&_.title]:hover:text-primary post"
      title={title}
    >
      <div className="flex flex-row gap-x-4">
        {!!hasTumbnail && (
          <div
            className="w-[104px] h-[64px] bg-black/10 rounded-md !bg-cover bg-center"
            style={styleBg}
          ></div>
        )}
        <div className={detailClass}>
          <Typography
            variant="strong"
            className="title text-base font-normal text-black/[.87]"
          >
            {title}
          </Typography>
          <Typography className="text-xs !text-black/60">{subtitle}</Typography>
        </div>
      </div>
    </Link>
  )
}
