import Image from "next/image"
import Link from "next/link"
import React from "react"

type Props = {
  linkable?: boolean
  isText?: boolean
}

export default function Logo({ linkable = true, isText = false }: Props) {
  let logoSource = isText
    ? "/assets/logo-vahdat-text.svg"
    : "/assets/logo-vahdat.svg"

  let content = (
    <Image width={179} height={52} src={logoSource} alt="لوگو وحدت‌اپتیک" />
  )
  return linkable ? (
    <Link href={`/fa`} prefetch={false} title="وحدت‌اپتیک">
      {content}
    </Link>
  ) : (
    content
  )
}
