"use client";

import React, { ReactNode } from "react";
import Typography from "./Typography";

export type TableItems = {
  title?: string;
  align?: any;
  colSpan?: number;
  className?: string;
  render(item: any, row: any): ReactNode | string | number;
};

type Props = {
  title?: string;
  moreLink?: string;
  tableHeadItems: TableItems[];
  items: any[];
  loading?: boolean;
  className?: string;
};

const Table = ({
  title = "",
  moreLink,
  tableHeadItems = [],
  items,
  loading = false,
  className,
}: Props) => {
  return (
    <div className={` ${className || ""}`}>
      {title && moreLink && (
        <div className="flex justify-between items-start mb-2">
          {title && <Typography>{title}</Typography>}
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              {tableHeadItems.map((item, key) => {
                let clss = ``;
                if (item.title)
                  clss = "px-4 py-2 text-center whitespace-nowrap";
                return (
                  <th className={clss} key={key}>
                    {item.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td>Loading...</td>
              </tr>
            ) : items.length === 0 ? (
              <tr>
                <td className="p-6 text-center">
                  <Typography>چیزی یافت نشد.</Typography>
                </td>
              </tr>
            ) : (
              items.map((item: any, rowKey: any) => {
                return (
                  <tr key={rowKey} className="odd:bg-black/[.02]">
                    {tableHeadItems.map((tdItem, tdKey) => (
                      <td
                        className={`px-4 py-2 whitespace-nowrap ${tdItem?.className}`}
                        key={tdKey}
                        colSpan={tdItem.colSpan}
                        align={tdItem.align}
                      >
                        {tdItem.render(item, rowKey)}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
