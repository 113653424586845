import React, { ReactNode } from "react";

type Props = {
  title?: string;
  children: ReactNode
  className?: string;
  titleClassName?: string;
};
export default function TitleEl({
  title,
  children,
  className,
  titleClassName = "title font-normal text-lg text-black",
}: Props) {
  return (
    <div className={`flex flex-col gap-y-4 ${className || ""}`}>
      {!!title && (
        <strong className={`title ${titleClassName}`}>{title}</strong>
      )}
      {children}
    </div>
  );
}
