"use client";

import { createPortal } from "react-dom";
import React, { MouseEvent, ReactNode, useEffect, useState } from "react";
import Paper from "./Paper";

export const useDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const closeDrawer = () => {
        setIsOpen(false);
        document
            .getElementsByTagName("body")?.[0]
            .classList.remove("overflow-hidden");
    };
    const openDrawer = () => {
        setIsOpen(true);
        document.getElementsByTagName("body")?.[0].classList.add("overflow-hidden");
    };

    return { open: openDrawer, close: closeDrawer, isOpen };
};

type Props = {
    children: ReactNode
    close: () => void;
    isOpen?: boolean;
    className?: string;
};

export default function Drawer({ children, isOpen, close, className }: Props) {
    const [open, setOpen] = useState(isOpen);
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const closeDrawerHandler = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        close();
    };

    if (!children) return null;

    if (!open) return null;

    let drawerClasses = `drawer animate-appear-drawer fixed right-0 transition-all modal-inner-holder z-[2] h-screen w-[400px] max-w-[calc(100%-150px)] rounded-tr-none rounded-br-none`;

    return (
        <>
            {createPortal(
                (
                    <>
                        <div
                            className={`fixed top-0 left-0 z-[10000] w-full h-screen flex flex-col items-center justify-center ${className || ""
                                }`}
                        >
                            <div
                                onClick={closeDrawerHandler}
                                className='absolute top-0 left-0 w-full h-full bg-black/[.2] backdrop-blur-lg transition-all z-[1]'
                            ></div>
                            <Paper className={drawerClasses}>{children}</Paper>
                        </div>
                    </>
                ) as any,
                document.getElementById("portal") as any
            )}
        </>
    );
}
