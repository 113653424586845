"use client"

import { ProductSiteType } from "@/app/types/product-site"
import { Typography } from "../ui"
import Link from "next/link"
import SwiperSlider from "./SwiperSlide"
import ProductCard from "@/app/(pages)/components/product/ProductCard"
import React from "react"

interface Props {
  products: ProductSiteType[]
  title: string
  productCardClassName?: string
  moreLink?: string
}

const ProductsSwiper = ({
  products = [],
  title,
  productCardClassName,
  moreLink,
}: Props) => {
  let currentSlides =
    products?.map((product) => {
      return (
        <ProductCard
          key={product.id}
          product={product}
          className={productCardClassName}
        />
      )
    }) || []

  let swiperConfig = {
    320: {
      slidesPerView: 2.5,
    },
    760: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    },
    1360: {
      slidesPerView: 4.5,
    },
  }

  if (!!!products) return null

  return (
    <div className="flex flex-col gap-y-2 w-full">
      <div className="flex items-center w-full justify-between">
        <Typography
          variant="h3"
          className="font-semibold md:text-[26px] text-[22px]"
        >
          {title}
        </Typography>
        {!!moreLink && (
          <Link href={moreLink} prefetch={false}>
            <Typography
              variant="span"
              className="font-semibold text-primary text-lg"
            >
              فهرست کامل
            </Typography>
          </Link>
        )}
      </div>
      <SwiperSlider
        className="relative [&_.swiper]:after:absolute [&_.swiper]:after:inset-y-0 [&_.swiper]:after:left-0 [&_.swiper]:after:z-[2] md:[&_.swiper]:after:w-[90px] [&_.swiper]:after:w-0  [&_.swiper]:after:h-full [&_.swiper]:after:bg-gradient-to-r [&_.swiper]:after:from-white [&_.swiper]:after:from-20% [&_.swiper]:after:to-transparent [&_.swiper]:before:absolute [&_.swiper]:before:inset-y-0 [&_.swiper]:before:right-0 [&_.swiper]:before:z-[2] md:[&_.swiper]:before:w-[90px] [&_.swiper]:before:w-0    [&_.swiper]:before:h-full [&_.swiper]:before:bg-gradient-to-l [&_.swiper]:before:from-white [&_.swiper]:before:from-20% [&_.swiper]:before:to-transparent"
        config={{
          breakpoints: swiperConfig,
        }}
        items={currentSlides}
      />
    </div>
  )
}

export default ProductsSwiper
