import React, { ReactNode } from "react"
import Container from "../Container"
import MobileHeader from "./Header"

interface Props {
  title: string | ReactNode
  children: ReactNode
  className?: string
  afterHeaderNode?: ReactNode
  onBack?: () => void
}

const MobileWrapperWithBack = ({
  title,
  children,
  className = "",
  afterHeaderNode,
  onBack,
}: Props) => {
  return (
    <div className={`flex flex-col w-full ${className}`}>
      <MobileHeader afterNode={afterHeaderNode} onBack={onBack} title={title} />
      <Container className="p-4 px-4 pt-[72px] h-full">{children}</Container>
    </div>
  )
}

export default MobileWrapperWithBack
