"use client"

import React, { ReactNode, useCallback, useEffect, useState } from "react"
import { TabItem } from "./Tabs"

type Props = {
  references: HTMLDivElement[]
  children: ({
    activeTab,
    changeTabHander,
    isEnd,
  }: {
    activeTab: number
    changeTabHander: (tab: TabItem, index: number) => void
    isEnd: boolean
  }) => ReactNode
}

const AdjustHomeTabsScroll = ({ references, children }: Props) => {
  const [activeTab, setActiveTab] = useState(0)
  const [scrollEnabled, setScrollEnabled] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  useEffect(() => {
    const handleHashChange = () => {
      const offsetAnchor = () => {
        const hash = window.location.hash
        const element = document.getElementById(hash.substring(1))
        if (element) {
          const y = element?.getBoundingClientRect().top + window.scrollY - 100
          window.scrollTo({ top: y, behavior: "smooth" })
        }
      }
      offsetAnchor()
    }
    window.addEventListener("hashchange", handleHashChange)
    return () => {
      window.removeEventListener("hashchange", handleHashChange)
    }
  }, []) // No dependencies, so it runs only once on component mount

  useEffect(() => {
    const handleScroll = () => {
      if (!scrollEnabled) {
        return
      } else {
        const tabElements = references

        // Iterate over tab elements to find which one is currently in view
        setActiveTab((crt) => {
          let activeTab = crt
          for (let i = 0; i < tabElements.length; i++) {
            const tabElement = tabElements[i]
            const rect = tabElement?.getBoundingClientRect()

            const lastElement = tabElements[tabElements.length - 1]

            if (lastElement?.getBoundingClientRect()?.bottom < 100) {
              setIsEnd(true)
            } else {
              setIsEnd(false)
            }
            // Check if the top of the tab element is above the middle of the viewport and the bottom is below the middle
            if (rect.top < 200 && rect.bottom >= 0) {
              activeTab = i
            }
          }
          return activeTab
        })
      }

      // Add scroll event listener
    }

    window.addEventListener("scroll", handleScroll)
    // Cleanup event listener
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [scrollEnabled])

  const changeHashHandler = useCallback((tab: TabItem) => {
    window.location.href = `#${tab.href}`

    setScrollEnabled(false)
    setTimeout(() => {
      setScrollEnabled(true)
    }, 500)

    // Disable scrolling temporarily
  }, [])

  return (
    <>{children({ activeTab, changeTabHander: changeHashHandler, isEnd })}</>
  )
}

export default AdjustHomeTabsScroll
