"use client";

import React, { ChangeEvent, ReactNode, useRef, useState } from "react";
import { AttachmentIcon } from "../icons";
import ToastMessage from "./ToastMessage";

type Props = {
  icon?: ReactNode;
  size?: number; // In kb
  accept?: string;
  onChange?: (files: FileList) => void;
};
export default function UploadIconButton({
  icon = <AttachmentIcon />,
  size = -1,
  accept,
  onChange,
}: Props) {
  const [error, setError] = useState(false);

  const fileRef = useRef<HTMLInputElement>(null);

  const changeFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.[0]) return;

    const sizeInKb = files?.[0]?.size / 1000;
    if (size !== -1 && sizeInKb > size) {
      {
        ToastMessage.warning(`حداکثر سایز جهت بارگذاری ${size}KB می‌باشد.`);
        setError(true);
        if (fileRef.current?.value) fileRef.current.value = "";
        setInterval(() => {
          setError(false);
        }, 2000);
        return;
      }
    }

    if (e.target.files) if (onChange) onChange(e.target.files);
    if (fileRef.current?.value) fileRef.current.value = "";
  };

  return (
    <>
      <label>
        <span
          className={`flex items-center justify-center w-[40px] h-[40px] cursor-pointer hover:bg-black/5 active:bg-black/10 rounded-full ${
            error ? "animate-shake [&_*]:fill-primary bg-primary/[0.05]" : ""
          }`}
        >
          {icon}
        </span>
        <input
          ref={fileRef}
          onChange={changeFileHandler}
          type="file"
          {...(accept ? { accept } : {})}
          hidden
        />
      </label>
    </>
  );
}
