"use client"

import React, { ReactNode } from "react"
import { Modal, PaperMobileFull } from "../ui"
import { CloseIcon } from "../icons"
import BoxHeader from "./BoxHeader"

type Props = {
  title?: string
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  endHeaderNode?: ReactNode
  icon?: ReactNode
  className?: string
  paperClss?: string
}

export default function ModalContentWrapper({
  title,
  isOpen,
  onClose,
  className = "",
  paperClss = "",
  children,
  icon = <CloseIcon />,
  endHeaderNode,
}: Props) {
  return (
    <Modal isOpen={isOpen} close={onClose} className={className}>
      <PaperMobileFull className={`!p-0 ${paperClss}`}>
        <BoxHeader
          title={title}
          icon={icon}
          onIconClick={onClose}
          className="!gap-y-0"
          afterHeadingNode={endHeaderNode}
        >
          <div className="content-children p-4">{children}</div>
        </BoxHeader>
      </PaperMobileFull>
    </Modal>
  )
}
