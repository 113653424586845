import { ElementType } from "@/app/types/element";
import { RightValue } from "../Item";
import { Divider } from "@/app/components/ui";
import React from "react";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function DividerElement({ item, value }: Props) {
  const height = value?.height ? +value.height : undefined;
  const color = value?.color ?? "#eee";

  return (
    <Divider height={height ?? 1} backgroundColor={color} className={`my-8`} />
  );
}
