import { ElementType } from "@/app/types/element";
import { RightValue } from "../Item";
import RenderHtml from "../../../RenderHtml";
import React from "react";

type Props = {
    item: ElementType;
    value?: RightValue;
};

export default function EtmlElement({ value }: Props) {
    if (!!!value?.html_editor) return null;

    return <RenderHtml html={value?.html_editor ?? ""} />;
}
