let animationFrameId = null;

export default function scrollToTop() {
  function animateScroll() {
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      animationFrameId = requestAnimationFrame(animateScroll);
    } else {
      cancelAnimationFrame(animationFrameId);
    }
  }

  if (!animationFrameId) {
    animationFrameId = requestAnimationFrame(animateScroll);
  } else {
    // If the user manually scrolls, cancel the animation frame
    cancelAnimationFrame(animationFrameId);
    animationFrameId = null;
  }
}
