import Link from "next/link"
import Post from "./Post"
import { Button } from "../../ui"
import ChevronLeft from "../../icons/ChevronLeft"
import { Colors } from "@/app/const/colors"
import React from "react"

type Props = {
  moreLink?: string
  moreText?: string
  className?: string
  items: any[]
  hasTumbnail?: boolean
}

export default function EntityVertical({
  moreLink,
  moreText = "مشاهده مطلب بیشتر",
  className,
  items = [],
  hasTumbnail,
}: Props) {
  return (
    <div className={`flex flex-col gap-y-4 ${className ?? ""}`}>
      <div className="flex flex-col gap-y-4 w-full">
        {items.map((item, key) => (
          <Post key={key} hasTumbnail={hasTumbnail} {...item} />
        ))}
      </div>
      {!!moreLink && (
        <Link href={moreLink} target="_blank" prefetch={false}>
          <Button
            className="!bg-transparent"
            variant="text"
            endIcon={<ChevronLeft color={Colors.primary} />}
          >
            {moreText}
          </Button>
        </Link>
      )}
    </div>
  )
}
