import { Typography } from "@/app/components/ui"
import { AttributeType } from "@/app/types/attribute"
import { ElementType } from "@/app/types/element"
import { getOptimizedMediaUrl, urlWithQueryParams } from "@/app/utils"
import Link from "next/link"
import React from "react"
import VahdatImage from "../../../VahdatImage"
import { RightValue } from "../Item"

type Props = {
    item: ElementType
    value?: RightValue
}

export default function AttributeLoaderElement({ item, value }: Props) {
    let attribute: AttributeType = value?.attribute ?? null

    return (
        <div className="grid grid-cols-12 gap-4">
            {attribute?.items?.map((item, index) => {
                const value = typeof item === "object" ? item?.value : item

                return (
                    <Link
                        href={urlWithQueryParams(`/fa/products`, {
                            [`attribute[${attribute?.id}]`]: value,
                        })}
                        prefetch={false}
                        key={index}
                        className="col-span-6 md:col-span-3"
                    >
                        <div className="flex flex-col min-h-[90px] gap-y-4 py-3 border border-black/10 hover:border-black/40 transition-all rounded-lg">
                            {typeof item === "object" ? (
                                <div className="flex flex-col items-center">
                                    <VahdatImage
                                        alt={value ?? ""}
                                        width={120}
                                        height={60}
                                        src={getOptimizedMediaUrl({
                                            mediaId: item?.media_id,
                                        })}
                                    />
                                    <Typography className="text-sm !text-black/60">
                                        {value}
                                    </Typography>
                                </div>
                            ) : (
                                <div className="flex flex-col min-h-[90px] items-center">
                                    <Typography className="text-sm !text-black/60">
                                        {value}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </Link>
                )
            })}
        </div>
    )
}
