"use client"

import { Skeleton } from "@/app/components/ui";
import React from "react";

export default function ProductsLoading() {
  return (
    <div className='flex flex-col items-center gap-y-2 w-full'>
      <Skeleton className='w-[196px] h-[56px] rounded-md' />
      {Array.from(Array(2).keys()).map((row, key) => (
        <div className='flex flex-col gap-y-2' key={key}>
          <div className='flex flex-row gap-2'>
            {Array.from(Array(3).keys()).map((_, index) => (
              <Skeleton key={index} className='h-[200px] w-[200px] rounded-md' />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
