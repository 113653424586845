var jwt = require('jsonwebtoken');

const decodeWithSecret = (token: string) => {
  return jwt.verify(token, process.env.NEXT_AUTH_SECRET, function(err: any, decoded: any) {

    if ( err ) return null

    return decoded
  })
}

export default decodeWithSecret