import { ElementType } from "@/app/types/element";
import { RightValue } from "../Item";
import { Typography } from "@/app/components/ui";
import React from "react";

type Props = {
    item: ElementType;
    value?: RightValue;
};

export default function ParagraphElement({ value }: Props) {
    const paragraphText = value?.text ?? null;
    const paragraphSize = value?.text_size ?? null;
    let className = "mt-0";
    if (value?.class) className += ` ${value?.class ?? ""}`;

    if (paragraphText === null) return null;

    let objectStyle = {};
    if (paragraphSize) objectStyle["font-size"] = `${paragraphSize}px`;

    return (
        <Typography variant={"p"} className={className} style={objectStyle}>
            {paragraphText}
        </Typography>
    );
}
