import React from "react";
import { IconProps, defaultIconColor } from ".";

export default function InstagramIcon({
  color = defaultIconColor,
  size = 18,
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 22.002H15C20 22.002 22 20.002 22 15.002V9.00195C22 4.00195 20 2.00195 15 2.00195H9C4 2.00195 2 4.00195 2 9.00195V15.002C2 20.002 4 22.002 9 22.002Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.502C13.933 15.502 15.5 13.9349 15.5 12.002C15.5 10.069 13.933 8.50195 12 8.50195C10.067 8.50195 8.5 10.069 8.5 12.002C8.5 13.9349 10.067 15.502 12 15.502Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6361 7.00195H17.6477"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
