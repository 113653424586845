import { Typography } from "@/app/components/ui";
import { AttachmentType } from "@/app/types/attachment";
import { ElementType } from "@/app/types/element";
import { getOptimizedMediaUrl } from "@/app/utils";
import Link from "next/link";
import React from "react";
import VahdatImage from "../../../VahdatImage";
import { RightValue } from "../Item";

type Props = {
    item?: ElementType;
    value?: RightValue;
};

export default function BoxImage({ item, value }: Props) {
    const media: AttachmentType | null = value?.media;

    let clss = value?.className || "";

    //Title
    const title = value?.title;

    //Link
    const link = value?.link;

    //Image width
    const width = value?.width ? `${value?.width}px` : "100%";

    //Image height
    const height = value?.height ? `${value?.height}px` : "100%";

    //Image alt
    const alt = value?.alt ?? "";

    if (media === undefined) return null;

    let titleNode = null;
    if (title) {
        let gradientNode = (
            <div className='absolute flex items-end top-0 left-0 w-full h-full from-black/70 to-transparent bg-gradient-to-t z-[10]'>
                <Typography
                    variant='h3'
                    className='text-white w-full text-center py-6 text-xl'
                >
                    {title}
                </Typography>
            </div>
        );

        if (link) {
            titleNode = (
                <Link href={link} prefetch={false}>
                    {gradientNode}
                </Link>
            );
        } else {
            titleNode = gradientNode;
        }
    }

    const mediaPath = media
        ? getOptimizedMediaUrl({ mediaId: media.id, width: 460, height: 550 })
        : "";

    return (
        <div
            className={`relative max-w-full [&_img]:object-cover ${clss}`}
            style={{
                width,
                height,
            }}
        >
            <VahdatImage fill src={mediaPath} alt={alt ?? title ?? ""} />
            {titleNode}
        </div>
    );
}
