"use client";

import React, { useCallback } from "react";
import { ChevronRightIcon } from "../../icons";
import { IconButton } from "../../ui";

type Props = {
  className?: string;
  onClick?: () => void;
  isDisabled?: boolean;
};

export default function SwiperPrevSlide({
  className = "",
  onClick,
  isDisabled = false,
}: Props) {
  const onClickHandler = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <IconButton
      disabled={isDisabled}
      className={`${className} slider-nav-btn slider-prev-btn bg-white border border-black/[.12]`}
      onClick={onClickHandler}
    >
      <ChevronRightIcon />
    </IconButton>
  );
}
