import { ElementType } from "@/app/types/element";
import TypeItem, { RightValue, isJson } from "../Item";
import { getOptimizedMediaUrl } from "@/app/utils";
import React from "react";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function ContainerElement({ item, value }: Props) {
  let containerClass =
    "relative container mx-auto max-w-full px-8 lg:px-4 xl:px-8 2xl:px-0";
  if (value?.class) {
    containerClass += ` ${value.class}`;
    if (!value.class.includes("w-")) {
      containerClass += ` !w-[1400px]`;
    }
  } else {
    containerClass += ` !w-[1400px]`;
  }

  let objectStyle = {};
  if (value?.bg_cover) {
    objectStyle["background"] = `url(${getOptimizedMediaUrl({
      mediaId: value.bg_cover.id,
    })})`;
    containerClass +=
      " !bg-cover !bg-center before:absolute before:z-[1] before:bottom-0 before:left-0 before:w-full before:top-0 before:from-black before:to-transparent before:bg-gradient-to-t [&>*]:z-[2]";
  }

  if (value?.height) objectStyle["height"] = `${value.height}px`;

  return (
    <div className={containerClass} style={objectStyle}>
      <TypeItem
        item={{
          item: value?.component?.component,
          value: value?.component?.value ?? "{}",
        }}
      />
    </div>
  );
}
