import React from "react";

type Props = {
  progress?: number;
};

export default function Progress({ progress = 0 }: Props) {
  return (
    <div className="w-full h-2 bg-[#F5F5F5] rounded-xl relative overflow-hidden">
      <div
        className="filled transition-all bg-primary rounded-xl absolute left-0 top-0 bottom-0"
        style={{
          width: `${progress}%`,
        }}
      ></div>
    </div>
  );
}
