import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import React, { ReactNode } from "react";

export type PopoverNewType = {
    children: ReactNode
    content: ReactNode;
};

type Props = PopoverNewType & {
    open?: boolean;
    onOpenChange?: (item: boolean) => void;
    alignment?: "start" | "center" | "end";
    triggerClass?: string;
};

export default function PopoverShadcnUi({
    children,
    content,
    alignment = "start",
    open,
    onOpenChange,
    triggerClass,
}: Props) {
    return (
        <Popover open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger className={` ${triggerClass ?? ""}`}>
                {content}
            </PopoverTrigger>
            <PopoverContent
                align={alignment}
                className='p-0 rounded-md border-0 animate-show-up overflow-hidden !bg-white  !w-auto'
            >
                {children}
            </PopoverContent>
        </Popover>
    );
}
