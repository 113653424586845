"use client";

import { ProductSiteType } from "@/app/types/product-site";
import ProductThumbnail from "./ProductThumbnail";
import React, { ReactNode, useState } from "react";
import { Chip } from "@/app/components/ui";
import ProductPrice from "./ProductPrice";
import Link from "next/link";
import { limitChars } from "@/app/utils";
import ProducTitle from "./ProducTitle";

type Props = {
  product?: ProductSiteType;
  beforeNode?: ReactNode;
  className?: string;
  thumbnailHeight?: number;
  thumbnailWidth?: number;
  titleTagType?: "h2" | "h3" | "span";
};

export default function ProductCard({
  product,
  beforeNode,
  className = "",
  thumbnailHeight = 166,
  thumbnailWidth = 295,
  titleTagType,
}: Props) {
  const isAvailable = !!product?.details?.find((item) => item.quantity > 0);

  const firstProduct = product?.details?.[0];

  let bottomProductCard = (
    <ProductPrice
      price={firstProduct?.price}
      salePrice={firstProduct?.final_price}
    />
  );

  if (!isAvailable)
    bottomProductCard = (
      <Chip
        label='ناموجود'
        className='!bg-black/60 !py-1 !px-[12px] text-white text-sm'
      />
    );

  const details = product?.details || [];

  const [isHoverState, setIsHoverState] = useState(false);
  const handleHoverStaet = () => setIsHoverState(true);
  const handleOutState = () => setIsHoverState(false);
  let targetThumbnail = details[0]?.images?.find((x) => x.default) || undefined;

  if (!targetThumbnail) details?.[0]?.images?.[0];

  if (isHoverState && details[0]?.images?.[1])
    targetThumbnail = details[0].images?.[1];

  return (
    <Link
      href={`/fa/products/${product.slug}`}
      title={product?.title ?? ""}
      prefetch={false}
    >
      <div
        className={`product-card w-full relative flex flex-col items-center justify-start m-auto gap-y-2 cursor-pointer ${className}`}
        onMouseOver={handleHoverStaet}
        onMouseOut={handleOutState}
      >
        <ProductThumbnail
          media={targetThumbnail || undefined}
          width={thumbnailWidth}
          height={thumbnailHeight}
          alt={targetThumbnail?.alt ?? ""}
          title={targetThumbnail?.title ?? ""}
        />
        {!!beforeNode && beforeNode}
        <div className='product-details relative flex flex-col items-start gap-y-1 px-4 w-full'>
          <ProducTitle title={product?.title} tagType={titleTagType} />
          <div className='min-h-[72px]'>{bottomProductCard}</div>
          {/* {!!isHoverState && (
            <div className='absolute bottom-0 left-0'>
              <ProductImages product={product} />
            </div>
          )} */}
        </div>
      </div>
    </Link>
  );
}
