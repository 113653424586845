"use client"

import React, { ReactNode, useCallback, useEffect, useState } from "react"

import { TitleEl } from "."
import { Box } from "@mui/material"
import { useAppSelector } from "@v2/lib/redux/hooks"

export type TabItem = {
    title: string
    children: ReactNode
    href?: string
    ref?: (ref: HTMLDivElement | null) => void
}

type Props = {
    title?: string
    items: TabItem[]
    beforeTabItems?: ReactNode
    className?: string
    showAllTabsContent?: boolean
    defaultActiveIndex?: number
    onChangeIndex?: (tab: TabItem, index: number) => void
}
export default function Tabs({
    items = [],
    beforeTabItems,
    title,
    className = "",
    defaultActiveIndex = 0,
    showAllTabsContent = false,
    onChangeIndex,
}: Props) {
    const [activeIndex, setActiveIndex] = useState(defaultActiveIndex)
    const headerIsShow = useAppSelector(s => s.general.headerIsShow)
    useEffect(() => {
        if (defaultActiveIndex !== undefined) setActiveIndex(defaultActiveIndex)
    }, [defaultActiveIndex])
    const changeActiveIndexHandler = useCallback(
        (tab: TabItem, index: number) => {
            setActiveIndex(index)
            if (onChangeIndex) onChangeIndex(tab, index)
        },
        [onChangeIndex]
    )

    if (items.length === 0) return null

    let content = items?.[activeIndex]?.children

    if (showAllTabsContent) {
        content = (
            <div className="flex flex-col w-full">
                {items.map((x, index) => {
                    const targetId = x?.href || ""
                    return (
                        <div
                            className="w-full tab-content-holder"
                            key={index}
                            id={targetId}
                            {...(x?.href && { ref: (ref) => x?.ref && x.ref(ref) })}
                        >
                            {x.children}
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <TitleEl title={title} className={`!text-base !h-full ${className}`}>
            <div className="tabs-holder flex flex-col gap-y-2 h-full w-full">
                <Box sx={{
                    zIndex: 20,
                    top: headerIsShow ? {
                        xs: '56px !important',
                        sm: '112px !important',
                        md: '72px !important'
                    } : '0 !important'
                }} className="tabs flex flex-row items-center border-b border-black/[.1] whitespace-nowrap max-w-full overflow-x-auto min-h-[50px]">
                    {!!beforeTabItems && beforeTabItems}
                    {items.map((tab, index) => {
                        const isActive = index === activeIndex

                        let className =
                            "tab-item px-[16px] py-[12px] cursor-pointer relative"

                        if (!isActive)
                            className += ` [&_span]:hover:text-primary [&_a]:hover:text-primary`

                        if (isActive)
                            className += ` active-tab [&_a]:text-primary [&_span]:text-primary after:absolute after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:bg-primary after:rounded-tl-[8px] after:rounded-tr-[8px]`

                        return (
                            <div
                                className={className}
                                key={index}
                                onClick={() => changeActiveIndexHandler(tab, index)}
                            >
                            <span className="text-black/[.38] font-medium">{tab.title}</span>

                            </div>
                        )
                    })}
                </Box>
                <div className="children h-full">{content}</div>
            </div>
        </TitleEl>
    )
}
