"use client"

import React, { ReactNode, useState } from "react"

import { Paper, PopoverShadcnUi } from "../ui"

type Props = {
    children: ReactNode
    alignment?: "start" | "center" | "end"
    content: (handleClick: Function) => ReactNode
}

export default function WrapperWithPopover({
    children,
    content,
    alignment,
}: Props) {
    const [isOpen, setOpen] = useState<boolean>(false)

    const id = isOpen
        ? `simple-popover-${Math.round(Math.random() * 100000)}`
        : undefined

    const openPopoverHandler = () => {
        setOpen(true)
    }

    return (
        <PopoverShadcnUi
            alignment={alignment}
            open={isOpen}
            onOpenChange={setOpen}
            content={content(openPopoverHandler)}
        >
            <Paper className="overflow-hidden !p-0">{children}</Paper>
        </PopoverShadcnUi>
    )
}
