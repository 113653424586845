import { ElementType } from "@/app/types/element";
import { RightValue, isJson } from "../Item";
import { Typography } from "@/app/components/ui";
import CategoryBox from "./CategoryBox";
import React, { Suspense } from "react";
import ProductsLoading from "./ProductsLoading";

type Props = {
  item: ElementType;
  value?: RightValue;
};

export default function CategoriesWithProducts({ item, value }: Props) {
  const sectionTitle = value?.title;
  const categories = value?.categories ?? [];

  if (categories?.length === 0) return null;

  return (
    <div className='flex flex-col gap-y-4 my-4'>
      {!!sectionTitle && (
        <Typography variant='h1' className='text-3xl text-center py-5'>
          {sectionTitle}
        </Typography>
      )}
      <div className='grid grid-cols-12 gap-y-12 md:gap-x-2'>
        {categories.map((category, key) => {
          let finalCategory = category.category ?? undefined;
          try {
            if (finalCategory && isJson(finalCategory))
              finalCategory = JSON.parse(finalCategory);
          } catch (e) {}

          return (
            <div className='col-span-12 xl:col-span-6' key={key}>
              <Suspense fallback={<ProductsLoading />}>
                <CategoryBox
                  key={key}
                  title={category?.title ?? ""}
                  category={finalCategory}
                />
              </Suspense>
            </div>
          );
        })}
      </div>
    </div>
  );
}
