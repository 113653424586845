import React, { Fragment, ReactNode } from "react"
import ChevronLeft from "../icons/ChevronLeft"
import Link from "next/link"
import Typography from "./Typography"

export type BreadcrumItem = {
  title: string
  href?: string
}

type Props = {
  className?: string
  divider?: ReactNode
  items: BreadcrumItem[]
}

export default function Breadcrumb({
  items = [],
  className = "",
  divider = <ChevronLeft size={16} />,
}: Props) {
  let targetItems = items

  if (targetItems.length === 0) return

  if (targetItems.length)
    targetItems = [
      {
        title: "خانه",
        href: "/",
      },
      ...targetItems,
    ]

  return (
    <div
      className={`flex flex-row items-center gap-x-[6px] text-black/[.38] [&_*]:fill-black/[.38] hover:[&_a]:text-primary flex-wrap ${className} main-breadcrumb`}
    >
      {targetItems.map((breadcrumbItem, index) => {
        let title = <Typography>{breadcrumbItem.title}</Typography>
        if (breadcrumbItem.href)
          title = (
            <Link href={breadcrumbItem.href} prefetch={false}>
              {breadcrumbItem.title}
            </Link>
          )

        return (
          <Fragment key={index}>
            {title}
            {!!(index <= items.length - 1) && divider}
          </Fragment>
        )
      })}
    </div>
  )
}
