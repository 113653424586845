import React, { ReactNode } from "react"

type Props = {
  className?: string
  children: ReactNode
}
export default function Container({ children, className = "" }: Props) {
  return (
    <div
      className={`container w-[1440px] mx-auto max-w-[100%] md:!max-w-[calc(100%-64px)] ${className}`}
    >
      {children}
    </div>
  )
}
