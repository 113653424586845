"use client";

import React, { ButtonHTMLAttributes, ReactNode } from "react";

type ButtonProps = {
  fullWidth?: boolean;
  afterNode?: ReactNode;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  loading?: boolean;
  className?: string;
  label?: string;
};

type Props = ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;

export default function Chip(props: Props) {
  let chipClasses = `flex flex-row items-center gap-x-2 py-2 px-4 border border-black/[.1] hover:bg-black/[.06] rounded-[32px] text-text-grey text-base`;

  const {startIcon, ...restProps} = props;
  let content = (
    <>
      <button
        {...restProps}
        disabled={!!props?.loading || props?.disabled}
        className={`${chipClasses} ${props?.className || ""}`}
        type="button"
      >
        {!!startIcon && startIcon}
        {props?.label}
        {!!props?.endIcon && props.endIcon}
      </button>
    </>
  );

  if (props?.afterNode) {
    return (
      <div className="flex flex-row items-center gap-x-2">
        {content}
        {props.afterNode}
      </div>
    );
  }

  return content;
}
