import { limitChars } from "@/app/utils";
import React, { ReactNode } from "react";

type Props = {
  title: string;
  tagType?: "h2" | "h3" | "span";
};
export default function ProducTitle({ title, tagType = "h2" }: Props) {
  const splittedTitle = title?.split("-");

  const persianTitle = splittedTitle?.[0] ?? title;
  const enTitle = splittedTitle?.[1] ?? undefined;

  const finalTitle = limitChars(persianTitle, 70);
  const titleClss =
    "text-base font-bold title text-right md:text-right min-h-[48px] lg:min-h-[initial] truncate";
  let titleNode: ReactNode = null;
  switch (tagType) {
    case "h2":
      titleNode = <h2 className={titleClss}>{finalTitle}</h2>;
      break;
    case "h3":
      titleNode = <h3 className={titleClss}>{finalTitle}</h3>;
      break;
    case "span":
      titleNode = <span className={titleClss}>{finalTitle}</span>;
      break;
  }

  return (
    <div className='flex flex-col gap-y-2 w-full'>
      {titleNode}
      {!!enTitle && (
        <span className='text-sm text-black/60 truncate w-[120px] md:max-w-full md:w-auto'>
          {enTitle}
        </span>
      )}
    </div>
  );
}
