"use client";

import React, { ChangeEvent, ReactNode, useCallback, useState } from "react";
import { CloseIcon, UploadIcon } from "../../icons";
import { IconButton, Typography } from "../../ui";
import FileViewer from "./FileViewer";

interface Props {
    title?: ReactNode;
    addText?: string;
    onFile?: (file: File) => void;
    onRemove?: () => void;
    className?: string;
    uploadWithButton?: boolean;
}

const uploader = ({
    title = "تصویر را بکشید و در اینجا رها کنید",
    addText = "انتخاب تصویر",
    className,
    uploadWithButton = true,
    onFile,
    onRemove,
}: Props) => {
    const [file, setFile] = useState<File | null>(null);

    const onUploadFile = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const f = e.target.files?.[0];
            if (onFile) onFile(f);
            setFile(f);
        },
        [onFile]
    );

    const onRemoveFile = useCallback(() => {
        if (onRemove) onRemove();
        setFile(null);
    }, []);

    const addButton = (
        <>
            <input
                hidden
                type="file"
                id="uploadButton"
                name="uploadButton"
                onChange={onUploadFile}
            />
            {!!uploadWithButton && (
                <div className="flex items-center cursor-pointer justify-center rounded-lg bg-primary text-white min-w-[150px] min-h-[42px]">
                    <span className="font-normal text-sm">{addText}</span>
                </div>
            )}
        </>
    );

    let uploadClss = `min-h-[260px] flex flex-col items-center justify-center w-full`;

    if (!!!file) uploadClss += " cursor-pointer";

    let titleHolder = title;

    if (typeof title === "string")
        title = (
            <Typography
                className="text-sm font-normal text-center text-black/[0.38]"
                variant="span"
            >
                {title}
            </Typography>
        );

    let currentView = (
        <label htmlFor="uploadButton" className={uploadClss}>
            <div className="flex flex-col items-center justify-center w-full h-full gap-y-6">
                <div className="flex flex-col items-center gap-y-[20px]">
                    <UploadIcon />
                    {titleHolder}
                </div>
                {addButton}
            </div>
        </label>
    );

    if (!!file) {
        currentView = (
            <div className="file-viewer relative w-full h-full">
                <IconButton
                    className="!bg-black/[0.6] hover:bg-black/[.38] !w-[30px] !h-[30px] absolute top-2 left-2"
                    onClick={onRemoveFile}
                >
                    <CloseIcon color="#fff" />
                </IconButton>
                <FileViewer file={file} />
            </div>
        );
    }

    return (
        <div
            className={`flex flex-col w-full border-2 rounded-xl border-dashed items-center overflow-hidden justify-center min-h-[260px] max-h-full ${className}`}
        >
            {currentView}
        </div>
    );
};

export default uploader;
