"use client"
import React, { useCallback, useEffect } from "react"
import { Button, Chip, Typography } from "../ui"
import { EditIcon } from "../icons"
import OtpHandler from "./OtpHandler"
import { useTimer } from "@/app/hooks"

type Props = {
  title: string
  mobile: string
  backToEnterMobile?: () => void
  className?: string
  resendLoading?: boolean
  isOtpDisable?: boolean
  onSubmitOtp: (code: string) => void
  onResendCode: () => Promise<void> | any
}

const EnterOtpCodeHandler = ({
  title,
  mobile = "",
  backToEnterMobile,
  isOtpDisable = false,
  resendLoading = false,
  className = "",
  onResendCode,
  onSubmitOtp,
}: Props) => {
  let clss = "flex flex-col gap-y-10 py-8 px-4 items-center"

  const { startTimer, time, isDisabled } = useTimer(120000)
  useEffect(() => {
    startTimer()
  }, [])

  const resendCodeHandler = useCallback(async () => {
    try {
      if (onResendCode) await onResendCode()
      startTimer()
    } catch (error) {}
  }, [onResendCode])

  return (
    <div className={`${clss} ${className}`}>
      <Typography variant="h1" className="text-[22px] font-medium text-center">
        {title}
      </Typography>
      <Typography variant="p" className="text-lg font-normal text-center">
        کدفعالسازی به شماره شما پیامک شد لطفا کد را وارد کنید.
      </Typography>
      <Chip
        label={mobile || ""}
        startIcon={<EditIcon color="#8E8E8E" />}
        onClick={backToEnterMobile}
      />
      <OtpHandler
        disabled={isOtpDisable}
        length={4}
        onCompleted={onSubmitOtp}
        className="[&_.otp-input]:relative [&_.otp-input]:after:bottom-[-8px] [&_.otp-input]:after:absolute [&_.otp-input]:after:w-full [&_.otp-input]:after:h-[1px] [&_.otp-input]:after:bg-black/[.08] [&_.otp-input]:after:transition-all [&_.otp-input.active]:after:!bg-primary [&_.otp-input.active]:after:h-[3px]"
      />
      {isDisabled ? (
        <span className="flex flex-row items-center h-[48px] text-black/[.6]">{`ارسال مجدد کد تا  ${time}  دقیقه دیگر`}</span>
      ) : (
        <Button loading={resendLoading} onClick={resendCodeHandler} fullWidth>
          ارسال مجدد کد
        </Button>
      )}
    </div>
  )
}

export default EnterOtpCodeHandler
