"use client"

import { useRouter } from "next/navigation"
import BackIcon from "../../icons/Back"
import { IconButton } from "../../ui"
import React, { Fragment, ReactNode, useCallback } from "react"
import { scrollToTop } from "@/app/utils"

interface Props {
  title: string | ReactNode
  onBack?: () => void
  afterNode?: ReactNode
}

const MobileHeader = ({ title, onBack, afterNode }: Props) => {
  const router = useRouter()

  const onBackHandler = useCallback(() => {
    if (onBack) {
      onBack()
    } else {
      router.back()
    }
    scrollToTop()
  }, [onBack])

  let titleEl = (
    <span className="mobile-title text-lg font-medium ">{title}</span>
  )

  if (typeof title !== "string") titleEl = <>{title}</>
  return <Fragment />
  return (
    <header className="mobile-header min-h-[56px] z-[1001] px-3 bg-white w-full fixed top-0 border-b-4 border-black/[.03]">
      <div className="header-items flex items-center w-full justify-between p-3">
        <IconButton className="back-icon" onClick={onBackHandler}>
          <BackIcon />
        </IconButton>
        <div className="title-node absolute top-1/2 -translate-y-1/2 right-1/2 translate-x-1/2">
          {titleEl}
        </div>
        {!!afterNode && <div className="after-node">{afterNode}</div>}
      </div>
    </header>
  )
}

export default MobileHeader
