import React, { HTMLAttributes, ReactNode } from "react";

type Props = {
  children: ReactNode
  className?: string;
  variant?: "span" | "p" | "strong" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
} & HTMLAttributes<any>;
export default function Typography({
  children,
  className = "",
  variant = "p",
  ...rest
}: Props) {
  const sharedClasses = `text-black/[.87] leading-[30px]`;

  switch (variant) {
    case "span":
      return (
        <span {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </span>
      );
    case "strong":
      return (
        <strong {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </strong>
      );
    case "h1":
      return (
        <h1 {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5 {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </h5>
      );
    case "h6":
      return (
        <h6 {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </h6>
      );
    default:
    case "p":
      return (
        <p {...rest} className={`${sharedClasses} ${className}`}>
          {children}
        </p>
      );
  }
}
