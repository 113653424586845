"use client"

import React, { ButtonHTMLAttributes } from "react"

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
    badge?: number
}

export default function IconButton(props: Props) {
    let clss = `w-[40px] h-[40px] transition-all opacity-100  bg-transparent hover:bg-black/5 active:bg-black/10 flex flex-col items-center justify-center rounded-full`
    if (props?.disabled) clss += `  !opacity-50 hover:bg-transparent`

    let content = (
        <button
            type="button"
            {...props}
            className={`${clss} ${props?.className || ""}`}
        >
            {props.children}
        </button>
    )

    return content
}
