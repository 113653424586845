"use client";

import React, { ReactNode, useState } from "react";
import { IconButton, Typography } from "../ui";
import { ArrowDownIcon, ArrowUpIcon } from "../icons";

type Props = {
  title: string;
  children: ReactNode
  className?: string;
};

export default function TogglerView({
  title,
  children,
  className = "",
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((crt) => !crt);

  return (
    <div
      className={`flex flex-col gap-y-2 ${
        isOpen ? "open" : "close"
      } ${className}`}
    >
      <div className='toggleView-heading flex flex-row items-center justify-between'>
        <Typography className='toggleView-title text-lg !text-black'>
          {title}
        </Typography>
        <IconButton className='expand-icon' onClick={toggleOpen}>
          {!isOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </IconButton>
      </div>
      {isOpen && <div className='content'>{children}</div>}
    </div>
  );
}
