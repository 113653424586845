import React, { ReactNode } from "react"
import ShowOnDevices from "./ShowOnDevices"
import { deviceName } from "@/app/const/device"

interface Props {
  mobileEl: ReactNode
  deskEl: ReactNode
  className?: string
}

const MobileDeskViewer = ({ mobileEl, deskEl, className }: Props) => {
  return (
    <>
      <ShowOnDevices devices={[deviceName.Tablet, deviceName.Desk]}>
        {deskEl}
      </ShowOnDevices>
      <ShowOnDevices devices={[deviceName.Mobile]}>{mobileEl}</ShowOnDevices>
    </>
  )
}

export default MobileDeskViewer
