"use client"

import React, { ReactNode } from "react"

type Props = {
  className?: string
  children: ReactNode
}
export default function Badge({ className, children }: Props) {
  return (
    <div
      className={`w-8 h-8 bg-primary text-white flex flex-col items-center justify-center rounded-full ${
        className || ""
      }`}
    >
      {children}
    </div>
  )
}
