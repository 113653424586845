import React, { ReactNode } from "react"

import Link from "next/link"

export type SimpleListMenuItemType = {
  id: number
  title: string
  handleClick?: Function
  href?: string
  icon?: ReactNode
}

type Props = {
  menu: SimpleListMenuItemType
  className?: string
  isActive?: boolean
}

export default function SimpleListMenuItem({
  menu,
  isActive = false,
  className,
}: Props) {
  let clss =
    "menu-item flex flex-row rounded-3xl py-2 px-4 gap-x-2 whitespace-nowrap text-black/[.6] hover:text-black hover:[&_.menu-item]:!text-black"

  if (!!className) {
    clss += ` ${className}`
  }

  if (!isActive)
    clss += ` text-black/[.6] [&_*]:hover:stroke-sapp-primary [&_*]:hover:!stroke-black`

  if (isActive)
    clss += ` is-active !bg-sapp-primary/[.1] hover:bg-sapp-primary/[.2] !text-sapp-primary [&_*]:!stroke-sapp-primary`

  let currentView = (
    <Link href={menu?.href || ""} prefetch={false} className={clss}>
      {!!menu?.icon && menu.icon}
      {menu.title}
    </Link>
  )

  if (!!menu?.handleClick || !menu.href) {
    const { handleClick } = menu
    currentView = (
      <div
        {...(handleClick && { onClick: (e) => handleClick(e) })}
        className={`flex flex-row items-center gap-2 cursor-pointer text-black/[.6] p-2 ${clss}`}
      >
        {!!menu?.icon && menu.icon}
        {menu.title}
      </div>
    )
  }

  return (
    <li key={menu.id} className="p-2">
      {currentView}
    </li>
  )
}
