"use client";

import { useQueryParams } from "@/app/hooks";
import { urlWithQueryParams } from "@/app/utils";
import { useRouter } from "@bprogress/next";
import { usePathname } from "next/navigation";
import { FC, useEffect, useState } from "react";
import { ArrowBackIcon, ArrowForwardIcon } from "../icons";
import IconButton from "./IconButton";

interface PaginationProps {
    total: number;
    perPage: number;
    onChange?: (currentPage: number) => void;
}

const Pagination: FC<PaginationProps> = ({ total, perPage, onChange }) => {
    const pathname = usePathname();
    const { query } = useQueryParams();
    const router = useRouter();

    const totalPages = Math.ceil(total / perPage);
    const [currentPage, setCurrentPage] = useState<number>(+query?.page || 1);
    useEffect(() => {
        if (query?.page !== undefined) setCurrentPage(+query.page);
    }, [query?.page]);

    useEffect(() => {
        if (onChange) {
            onChange(currentPage);
        }
        setCurrentPage(currentPage);
    }, [currentPage, onChange, pathname]);

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            router.push(
                urlWithQueryParams(pathname, {
                    ...query,
                    page: newPage,
                })
            );
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers: (number | string)[] = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const maxVisiblePages = 5;
            const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);

            if (currentPage <= halfMaxVisiblePages + 1) {
                for (let i = 1; i <= 4; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            } else if (currentPage >= totalPages - halfMaxVisiblePages) {
                pageNumbers.push(1);
                pageNumbers.push("...");
                for (let i = totalPages - 3; i <= totalPages; i++) {
                    pageNumbers.push(i);
                }
            } else {
                pageNumbers.push(1);
                pageNumbers.push("...");
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            }
        }
        return pageNumbers;
    };

    if (totalPages === 1) return;

    return (
        <div>
            <ul className="pagination flex flex-row items-center gap-x-2">
                {currentPage > 1 && (
                    <li className={`page-item prev-btn max-sm:hidden`}>
                        <IconButton onClick={() => handlePageChange(currentPage - 1)}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </li>
                )}

                {generatePageNumbers().map((pageNumber, index) => {
                    let clss = `page-item transition-all rounded-lg`;
                    if (currentPage === pageNumber) {
                        clss += ` bg-primary text-white`;
                    }

                    return (
                        <li
                            key={index}
                            className={clss}
                            onClick={() => handlePageChange(pageNumber as number)}
                        >
                            <IconButton className="page-number rounded-lg">
                                {pageNumber}
                            </IconButton>
                        </li>
                    );
                })}
                {currentPage < totalPages && (
                    <li className={`page-item forward-btn max-sm:hidden`}>
                        <IconButton onClick={() => handlePageChange(currentPage + 1)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Pagination;
