import VahdatImage from "@/app/components/shared/VahdatImage"
import { Typography } from "@/app/components/ui"
import { BlogType } from "@/types/blog"
import moment from "jalali-moment"
import Link from "next/link"
import React from "react"

type Props = {
    item: BlogType
}

const BlogCard = ({ item }: Props) => {
    return (
        <div className="flex w-full flex-col gap-y-4">
            <Link
                prefetch={false}
                href={item.link}
                title={item.title}
                target="_blank"
            >
                <VahdatImage
                    width={379}
                    height={200}
                    src={item.post_thumbnail}
                    alt=""
                    className="h-[200px] w-full overflow-hidden object-cover object-center rounded-md"
                />
            </Link>
            <Link
                href={item.link}
                prefetch={false}
                title={item.title}
                target="_blank"
                className="hover:text-primary [&_span]:hover:text-primary"
            >
                <Typography variant="span" className="font-medium text-lg">
                    {item.title}
                </Typography>
            </Link>
            <Typography variant="span" className="text-sm text-black/[0.3]">
                {moment(item.date).locale("fa").fromNow()}
            </Typography>
        </div>
    )
}

export default BlogCard
