"use client";

import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { IconButton } from "..";
import { ArrowDownIcon } from "../../icons";
import Item from "./Item";

export type DropDownItemType = {
  title: string;
  label?: string;
  value: string | number;
};

type Props = {
  afterNode?: ReactNode;
  hasNumberFormat?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onSearchChange?: (val: string) => void;
  hasSearch?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  items?: DropDownItemType[];
  onSelect?: (item: DropDownItemType) => void;
};

const Dropdown = forwardRef<HTMLInputElement, Props>(function Dropdown(
  props: Props,
  ref
) {
  const {
    afterNode,
    inputProps,
    hasNumberFormat = false,
    label,
    hasSearch = false,
    error = false,
    helperText,
    items = [],
    onSelect,
  } = props;

  const [searchValue, setSearchValue] = useState<any>(props?.inputProps?.value);
  useEffect(() => {
    setSearchValue(props?.inputProps?.value);
  }, [props?.inputProps?.value]);
  const searchValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onSearchChangeHandler(e.target.value);
    setSearchValue(value);
  };

  let inputValue = searchValue;
  if (hasNumberFormat) inputValue = searchValue?.toLocaleString();

  let inputClass = `block !cursor-pointer outline-none w-full rounded-md border border-black/[.2] h-[56px] pr-4 text-gray-900  placeholder:text-gray-400 focus:border-blue-400 sm:leading-6 text-lg ${inputProps?.className}`;

  const closeAvoiderClass = useMemo(
    () => `rnd-class-${parseInt("" + Math.random() * 1000)}`,
    []
  );

  inputClass += ` ${closeAvoiderClass}`;

  let helperTextClass = "text-[14px] text-black/[.6]";
  if (error) {
    //Change helper text class
    helperTextClass += " !text-[14px] !text-red-500";

    //Change input class
    inputClass += ` !border-red-500`;
  }

  const [isOpen, setIsOpen] = useState(false);
  const openDropdownHandler = (e: any) => {
    setIsOpen(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const checkIsCurrentInput = (classes: any[], str: string) => {
    for (let i of classes) {
      if (i === str) return true;
    }
    return false;
  };

  const closeDropdownHandler = (e: any) => {
    if (
      e?.type === "click" &&
      checkIsCurrentInput(e.target.classList, closeAvoiderClass)
    )
      return;
    setIsOpen(false);
  };

  const onSearchChangeHandler = (search: string) => {
    if (props.onSearchChange) props.onSearchChange(search);
  };

  useEffect(() => {
    document.addEventListener("click", closeDropdownHandler);
    return () => {
      document.removeEventListener("click", closeDropdownHandler);
    };
  }, []);

  const itemSelectHandler = useCallback(
    (item: DropDownItemType) => {
      if (onSelect) onSelect(item);
    },
    [onSelect]
  );

  let filteredItems = items || [];

  if (!!hasSearch && !!searchValue)
    filteredItems = filteredItems.filter((item) => {
      return (
        item.title.toLowerCase().includes("" + searchValue?.toLowerCase()) ||
        item.label?.toLowerCase().includes("" + searchValue?.toLowerCase())
      );
    });

  return (
    <div className="relative w-full">
      <div className="w-full flex flex-col items-start gap-y-2">
        {label && <span className="text-[14px]">{label}</span>}
        <div
          className="w-full relative rounded-lg"
          onClick={openDropdownHandler}
        >
          <div className="pointer-events-none absolute inset-y-0 left-0 top-0 flex flex-row gap-x-2 items-center pl-2">
            <IconButton className="!bg-transparent">
              <ArrowDownIcon />
            </IconButton>
            {!!afterNode && afterNode}
          </div>
          <input
            type="text"
            {...inputProps}
            onChange={searchValueHandler}
            value={inputValue}
            className={inputClass}
            ref={ref}
          />
        </div>
        {helperText && (
          <span className={`flex mt-1 ${helperTextClass}`}>{helperText}</span>
        )}
      </div>
      {isOpen && (
        <div
          className={`absolute z-[2] right-0 w-full top-[calc(100%-${
            error ? "24px" : "0px"
          })] bg-white popover dropdown shadow-md rounded-lg py-4 px-2 flex flex-col gap-y-2 max-h-[300px] overflow-y-auto  ${closeAvoiderClass}`}
        >
          {filteredItems.map((item, index) => (
            <Item item={item} key={index} onClick={itemSelectHandler} />
          ))}
        </div>
      )}
    </div>
  );
});

export default Dropdown;
