"use client";
import { createPortal } from "react-dom";
import React, { MouseEvent, ReactNode, useEffect, useState } from "react";

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
    document
      .getElementsByTagName("body")?.[0]
      .classList.remove("overflow-hidden");
  };
  const openModal = () => {
    setIsOpen(true);
    document.getElementsByTagName("body")?.[0].classList.add("overflow-hidden");
  };

  return { open: openModal, close: closeModal, isOpen };
};

type Props = {
  children: ReactNode
  close: () => void;
  isOpen?: boolean;
  className?: string;
};

export default function Modal({ children, isOpen, close, className }: Props) {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const closeModalHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    close();
  };

  if (!children) return null;

  if (!open) return null;

  return (
    <>
      {createPortal(
        (
          <>
            <div
              className={`fixed top-0 left-0 z-[10000] w-full h-screen flex flex-col items-center justify-center ${
                className || ""
              }`}
            >
              <div
                onClick={closeModalHandler}
                className='absolute top-0 left-0 w-full h-full bg-black/[.7] backdrop-blur-lg transition-all z-[1]'
              ></div>
              <div className='relative modal-inner-holder z-[2] max-w-[calc(100%-16px)] max-h-[calc(100%-32px)] md:max-w-full'>
                {children}
              </div>
            </div>
          </>
        ) as any,
        document.getElementById("portal") as any
      )}
    </>
  );
}
