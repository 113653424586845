"use client";

import Link from "next/link";
import { Button, Typography } from "../ui";
import SwiperSlider from "./SwiperSlide";
import ProductCard from "@/app/(pages)/components/product/ProductCard";
import { ProductSiteType } from "@/app/types/product-site";
import { ChevronLeftIcon } from "../icons";
import { Colors } from "@/app/const/colors";
import React from "react";

interface Props {
  products: ProductSiteType[];
  title: string;
  iconUrl?: string;
  btnText?: string;
  btnLink?: string;
  bgColor?: string;
  headingTitleColor?: string;
}

const ProductCollectionSwiperNew = ({
  products = [],
  title,
  iconUrl,
  btnLink,
  btnText,
  bgColor = "rgba(0,0,0,0.05)",
  headingTitleColor = "rgba(0,0,0,0.87)",
}: Props) => {
  let swiperItems =
    products?.map((product, key) => {
      let clss = "bg-white overflow-hidden !gap-y-1";

      if (key === 0) clss += ` rounded-tr-[8px] rounded-br-[8px]`;
      if (products.length - 1 === key)
        clss += ` rounded-tl-[8px] rounded-bl-[8px] ml-4`;

      return (
        <ProductCard className={clss} key={product.id} product={product} />
      );
    }) || [];

  let swiperConfig = {
    spaceBetween: 2,
    breakpoints: {
      320: {
        slidesPerView: 1.5,
      },
      760: {
        slidesPerView: 2.5,
      },
      1000: {
        slidesPerView: 3.5,
      },
    },
  };

  return (
    <div
      className="overflow-hidden relative rounded-2xl w-full flex flex-col md:flex-row gap-x3 items-center [&_.swiper]:py-4 [&_.swiper]:after:w-[90px] [&_.swiper]:after:h-full [&_.swiper]:after:absolute [&_.swiper]:after:left-0 [&_.swiper]:after:inset-y-0 [&_.swiper]:after:bg-gradient-to-r [&_.swiper]:after:from-0% [&_.swiper]:after:from-white/[0.1] [&_.swiper]:after:to-100% [&_.swiper]:after:to-transparent [&_.swiper]:after:z-[2] [&_.slider-nav-btn]:shadow-md   [&_.slider-prev-btn]:right-5 [&_.slider-next-btn]:left-5"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className="flex flex-col items-center justify-center text p-8 gap-y-10">
        <Typography
          variant="span"
          className="font-semibold text-2xl whitespace-nowrap"
          style={{
            color: headingTitleColor,
          }}
        >
          {title}
        </Typography>
        {!!iconUrl && (
          <img
            alt={title}
            className="w-[205px] h-auto"
            width={"auto"}
            height={"auto"}
            src={iconUrl}
          />
        )}
        {!!btnLink && !!btnText && (
          <Link href={btnLink} prefetch={false} title={btnText}>
            <Button
              endIcon={<ChevronLeftIcon color={Colors.white} />}
              variant="contained"
              className="whitespace-nowrap"
            >
              {btnText}
            </Button>
          </Link>
        )}
      </div>
      <div className="flex w-full items-center overflow-hidden mr-8 md:mr-0">
        <SwiperSlider config={swiperConfig} items={swiperItems} />
      </div>
    </div>
  );
};

export default ProductCollectionSwiperNew;
