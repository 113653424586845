import { Typography } from "../ui";

type Props = {
    price: number;
    currency?: string;
    className?: string;
    type?: 'increase' | 'decrease'
};
export default function Price({
    price,
    currency = "تومان",
    className = "",
    type = "increase"
}: Props) {
    if (price == undefined) return null;

    return (
        <div className={`flex flex-row items-center gap-x-2 ${className}`}>
            <span className='text-black price'>
                {!price ? 0 : price.toLocaleString()}
            </span>
            <span className='text-sm text-black/60'>{currency}</span>
        </div>
    );
}
