import { Button, Typography } from "@/app/components/ui"
import Link from "next/link"
import React from "react";

type Props = {
  title: string
  description: string
  btnText: string
  btnLink: string
}
export default function SlideDetails({
  title,
  description,
  btnLink,
  btnText,
}: Props) {
  return (
    <div className="flex flex-col gap-y-12 relative z-[2]">
      <div className="flex flex-col gap-y-4">
        <Typography variant="h2" className="text-white font-bold text-3xl">
          {title}
        </Typography>
        <Typography variant="p" className="text-white text-xl">
          {description}
        </Typography>
      </div>
      {!!btnLink && !!btnText && (
        <Link href={btnLink} title={title} prefetch={false}>
          <Button variant="contained" className="!bg-white !text-black">
            {btnText}
          </Button>
        </Link>
      )}
    </div>
  )
}
