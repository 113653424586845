import { useEffect, useState } from "react";

const useLoading = (defaultVal: boolean = false) => {
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(defaultVal);
  }, [defaultVal]);

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  return { isLoading, startLoading, stopLoading };
};
export default useLoading;
